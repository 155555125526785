import {
  Projection,
  ProjectionType,
  BonusAllocation,
  ScenarioBuilder,
  EventProjectionStatus,
  ScenarioBuilderProjection,
} from '@types';

export const mockProjectionScenarios = [
  {
    label: 'Internal Forecast',
    value: 'internal-forecast',
  },
  {
    label: 'Scenario 1',
    value: 'scenario-1',
  },
  {
    label: 'Scenario 2',
    value: 'scenario-2',
  },
  {
    label: 'NOS Settlement',
    value: 'nos-settlement',
  },
];

export const mockProjections: Projection = {
  id: 'test-guid',
  totalActiveProjection: {
    showsCount: 10,
    sellableCapacity: 32161,
    ticketSales: 19297,
    salesPercentage: 0.6,
    method: 'Varies',
    gross: 545269.00,
    averageGrossPrice: 25.43,
    netGross: 636760.64,
    averageNetPrice: 25.43,
    poolAdjustments: 0.00,
    fixedCosts: -289545.00,
    variableCosts: -28574.28,
    totalShowExpenses: -318119.28,
    netShowReceipts: 318641.36,
    guarantee: 307000.00,
    overage: 10094.63,
    artistEarnings: 317094.63,
    promoterProfit: 1546.73,
    ancillaries: 286511.28,
    eventPL: 288058.01,
    coProShare: -10824.65,
    aegPL: 277233.36,
    avgBreakEven: 16887,
    seqBreakEven: 16984,
    bonusAllocation: 0,
  },
  perShowProjection: {
    sellableCapacity: 1398,
    ticketSales: 1089,
    gross: 24188.82,
    netGross: 27685.25,
    poolAdjustments: 0.00,
    fixedCosts: -12588.91,
    variableCosts: -1242.36,
    totalShowExpenses: -13831.27,
    netShowReceipts: 13853.97,
    guarantee: 13347.83,
    overage: 438.90,
    artistEarnings: 13786.72,
    promoterProfit: 67.25,
    ancillaries: 12457.01,
    eventPL: 12524.26,
    coProShare: -470.64,
    aegPL: 12053.62,
    avgBreakEven: 734,
    seqBreakEven: 738,
    bonusAllocation: 0,
  },
  eventProjections: [
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd6110',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Georgia Theatre',
        city: 'Athens',
        stateCode: 'GA',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 1,
      status: EventProjectionStatus.Approved,
      date: '2024-03-21T23:07:47.650Z',
      sellableCapacity: 978,
      ticketSales: 587,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 14525.00,
      averageGrossPrice: 18.71,
      netGross: 12814.81,
      averageNetPrice: 18.71,
      poolAdjustments: 0.00,
      fixedCosts: -6250.00,
      variableCosts: -505.25,
      totalShowExpenses: -6755.25,
      netShowReceipts: 6059.56,
      guarantee: 6500.00,
      overage: 0.00,
      artistEarnings: 6500.00,
      promoterProfit: -440.44,
      ancillaries: 7050.00,
      eventPL: 6609.56,
      coProShare: 0.00,
      aegPL: 6609.56,
      avgBreakEven: 452,
      seqBreakEven: 452,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd611',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Rams Head Live!',
        city: 'Baltimore',
        stateCode: 'MD',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Draft,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: 4601.99,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: -89.06,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd612',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Royale',
        city: 'Boston',
        stateCode: 'MA',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Confirmed,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 1.0,
      method: ProjectionType.Average,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: -4109.02,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: 2054.51,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd6113',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'The Factory',
        city: 'Chesterfield',
        stateCode: 'MO',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 1,
      status: EventProjectionStatus.Approved,
      date: '2024-03-21T23:07:47.650Z',
      sellableCapacity: 978,
      ticketSales: 587,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 14525.00,
      averageGrossPrice: 18.71,
      netGross: 12814.81,
      averageNetPrice: 18.71,
      poolAdjustments: 0.00,
      fixedCosts: -6250.00,
      variableCosts: -505.25,
      totalShowExpenses: -6755.25,
      netShowReceipts: 6059.56,
      guarantee: 6500.00,
      overage: 0.00,
      artistEarnings: 6500.00,
      promoterProfit: -440.44,
      ancillaries: 7050.00,
      eventPL: 6609.56,
      coProShare: 0.00,
      aegPL: 6609.56,
      avgBreakEven: 452,
      seqBreakEven: 452,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd614',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Agora Theatre',
        city: 'Cleveland',
        stateCode: 'OH',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Draft,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: 4601.99,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: -89.06,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd615',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'The Senate',
        city: 'Columbia',
        stateCode: 'SC',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Confirmed,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 1.0,
      method: ProjectionType.Average,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: -4109.02,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: 2054.51,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    }, {
      id: '6e12426e-99a3-45e8-8481-6c611bfd6116',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Newport Music Hall',
        city: 'Columbus',
        stateCode: 'OH',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 1,
      status: EventProjectionStatus.Approved,
      date: '2024-03-21T23:07:47.650Z',
      sellableCapacity: 978,
      ticketSales: 587,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 14525.00,
      averageGrossPrice: 18.71,
      netGross: 12814.81,
      averageNetPrice: 18.71,
      poolAdjustments: 0.00,
      fixedCosts: -6250.00,
      variableCosts: -505.25,
      totalShowExpenses: -6755.25,
      netShowReceipts: 6059.56,
      guarantee: 6500.00,
      overage: 0.00,
      artistEarnings: 6500.00,
      promoterProfit: -440.44,
      ancillaries: 7050.00,
      eventPL: 6609.56,
      coProShare: 0.00,
      aegPL: 6609.56,
      avgBreakEven: 452,
      seqBreakEven: 452,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd617',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Trees',
        city: 'Dallas',
        stateCode: 'TX',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Draft,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: 4601.99,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: -89.06,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd618',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'The Majestic',
        city: 'Detroit',
        stateCode: 'MI',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Confirmed,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 1.0,
      method: ProjectionType.Average,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: -4109.02,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: 2054.51,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
    {
      id: '6e12426e-99a3-45e8-8481-6c611bfd619',
      eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
      settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
      venue: {
        id: 'test-guid',
        name: 'Gothic Theatre',
        city: 'Englewood',
        stateCode: 'CO',
        country: 'Test Country',
        spaces: [],
        cityCode: 'LA',
        ownership: 'test',
        status: 'test',
      },
      showsCount: 2,
      status: EventProjectionStatus.Draft,
      date: '2024-03-14T23:07:47.650Z',
      sellableCapacity: 1450,
      ticketSales: 870,
      salesPercentage: 0.6,
      method: ProjectionType.Manual,
      gross: 23925.00,
      averageGrossPrice: 22.27,
      netGross: 32295.45,
      averageNetPrice: 22.27,
      poolAdjustments: 0.00,
      fixedCosts: -9300.00,
      variableCosts: -505.25,
      totalShowExpenses: -10384.25,
      netShowReceipts: 21911.23,
      guarantee: 15000.00,
      overage: 2309.24,
      artistEarnings: 17309.24,
      promoterProfit: 4601.99,
      ancillaries: 222457.00,
      eventPL: 27076.99,
      coProShare: -89.06,
      aegPL: 27076.99,
      avgBreakEven: 657,
      seqBreakEven: 657,
      bonusAllocation: 0,
      updatedDate: '2024-03-22T23:07:47.650Z',
    },
  ],
};

export const mockScenarioBuilderData: ScenarioBuilder[] = [
  {
    name: 'Sales % and Forecast Method',
    eventOffers: [
      {
        id: 'scenario-id-1',
        bundleId: 'bundle-id-1',
        partitionKey: 'partition-key-1',
        venue: {
          id: 'venue-id',
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          country: 'Test Country',
          spaces: [],
          cityCode: 'LA',
          ownership: 'test',
          status: 'test',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        updatedDate: '2024-01-22T22:10:41.154Z',
        scenarios: [
          {
            type: null,
            amount: null,
          },
          {
            type: null,
            amount: null,
          },
        ],
        averageBreakeven: {
          amount: 0.53,
          type: ProjectionType.Average,
        },
        sequentialBreakeven: {
          amount: 0.53,
          type: ProjectionType.Sequential,
        },
      },
      {
        id: 'scenario-id-2',
        bundleId: 'bundle-id-1',
        partitionKey: 'partition-key-1',
        venue: {
          id: 'venue-id',
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          country: 'Test Country',
          spaces: [],
          cityCode: 'LA',
          ownership: 'test',
          status: 'test',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        updatedDate: '2024-01-22T22:10:41.154Z',
        scenarios: [
          {
            type: null,
            amount: null,
          },
          {
            type: null,
            amount: null,
          },
        ],
        averageBreakeven: {
          amount: 0.53,
          type: ProjectionType.Average,
        },
        sequentialBreakeven: {
          amount: 0.53,
          type: ProjectionType.Sequential,
        },
      },
    ],
    summary: {
      scenarios: [
        {
          type: null,
          amount: null,
        },
        {
          type: null,
          amount: null,
        },
      ],
      averageBreakeven: {
        amount: 0.53,
        type: ProjectionType.Average,
      },
      sequentialBreakeven: {
        amount: 0.53,
        type: ProjectionType.Sequential,
      },
    },
  },
];

export const mockScenarioBuilderProjectionData: ScenarioBuilderProjection = {
  scenarios: [
    {
      ticketSales: 12297,
      sellableCapacity: 32161,
      netGrossPublic: 480744.00,
      totalArtistEarnings: 308125.00,
      clubTourPL: 77674.00,
    },
    {
      ticketSales: 31161,
      sellableCapacity: 32161,
      netGrossPublic: 801240.00,
      totalArtistEarnings: 371168.00,
      clubTourPL: 434575.00,
    },
  ],
  averageBreakeven: {
    ticketSales: 16887,
    sellableCapacity: 31161,
    netGrossPublic: 431182.00,
    totalArtistEarnings: 307000.00,
    clubTourPL: 2874.00,
  },
  sequentialBreakeven: {
    ticketSales: 16887,
    sellableCapacity: 31161,
    netGrossPublic: 425909.00,
    totalArtistEarnings: 307000.00,
    clubTourPL: -3078.00,
  },
};

export const mockBonusAllocationData: BonusAllocation[] = [
  {
    name: 'Bonus Allocation',
    summary: {
      aegPL: 6609.56,
      bonusAllocations: [
        {
          type: null,
          amount: null,
        },
        {
          type: null,
          amount: null,
        },
      ],
    },
    eventOffers: [
      {
        id: 'test-guid-1',
        bundleId: 'bunlde-id-1',
        partitionKey: 'partition-key-1',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        updatedDate: '2024-03-22T07:00:00.000Z',
        bonusAllocations: [
          {
            amount: null,
            type: null,
          },
          {
            amount: null,
            type: null,
          },
        ],
        aegPL: 6609.56,
      },
      {
        id: 'test-guid-2',
        bundleId: 'bunlde-id-2',
        partitionKey: 'partition-key-2',
        venue: {
          id: 'test-guid',
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          country: 'Test Country',
          spaces: [],
          cityCode: 'LA',
          ownership: 'test',
          status: 'test',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        updatedDate: '2024-03-22T07:00:00.000Z',
        aegPL: 27076.99,
        bonusAllocations: [
          {
            amount: null,
            type: null,
          },
          {
            amount: null,
            type: null,
          },
        ],
      },
    ],
  },
];
