import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import {
  TextFieldCell,
  VenueNameCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import { formatNumberToCurrency, formatNumberToPercentage } from '@utils/numberHelpers';
import { BonusAllocation, BonusAllocationEventOffer, BonusAllocationType } from '@types';

export type BonusAllocationRow = BonusAllocation & BonusAllocationEventOffer;
type BonusAllocationColumns = GridColDef<BonusAllocationRow>[];
type RenderCellParams = GridRenderCellParams<BonusAllocationRow>;

const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 178,
  maxWidth: 248,
};

const aegPLColumn = {
  field: 'aegPL',
  headerName: 'AEG P&L',
  flex: 0.2,
  minWidth: 145,
  cellClassName: 'disabled-cell',
  renderCell: ({ row }: RenderCellParams) => (
    <TextFieldCell
      variant="standard"
      value={formatNumberToCurrency(row.summary?.aegPL ?? row.aegPL)}
      disabled
    />
  ),
};

const bonusPercentageOfAegPLColumn = {
  field: 'bonusPercentageOfAegPL',
  headerName: 'Bonus (% of AEG P&L)',
  flex: 0.2,
  minWidth: 145,
  renderCell: ({ row }: RenderCellParams) => (
    <TextFieldCell
      variant="standard"
      placeholder='Enter percentage'
      defaultValue={formatNumberToPercentage((row.summary?.bonusAllocations ?? row.bonusAllocations).find(
        (allocation) => allocation.type === BonusAllocationType.Percent,
      )?.amount)}
    />
  ),
};

const bonusFixedAmount = {
  field: 'bonusFixedAmount',
  headerName: 'Bonus (Fixed Amount)',
  flex: 0.2,
  minWidth: 145,
  renderCell: ({ row }: RenderCellParams) => (
    <TextFieldCell
      variant="standard"
      placeholder='Enter amount'
      defaultValue={formatNumberToCurrency((row.summary?.bonusAllocations ?? row.bonusAllocations).find(
        (allocation) => allocation.type === BonusAllocationType.Fixed,
      )?.amount, { nullishValue: '' })}
    />
  ),
};

export const bonusAllocationColumns: BonusAllocationColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ExpandButtonCell,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell>{row.name}</TextWrapPrettyCell>
      </LeftPinnedCell>
    ),
  },
  aegPLColumn,
  bonusPercentageOfAegPLColumn,
  bonusFixedAmount,
];

export const bonusAllocationEventOfferColumns: BonusAllocationColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          eventId={row.eventId}
          offerId={row.offerId}
          settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  aegPLColumn,
  bonusPercentageOfAegPLColumn,
  bonusFixedAmount,
];
