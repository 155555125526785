import {
  Box, styled, CustomTheme,
} from '@mui/material';

export const SelectedVenueHeader = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    padding: theme?.spacing(1.25, 1, 1, 1),
  }),
);

export const BackToSearch = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    gap: theme?.spacing(1),
    color: theme?.palette.info.main,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: theme?.spacing(1.75),
    paddingLeft: theme?.spacing(1),
  }),
);

export const VenueMetadata = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    marginBottom: theme?.spacing(2.25),
    marginTop: theme?.spacing(1),
    paddingLeft: theme?.spacing(2.5),
    paddingRight: theme?.spacing(2.5),
    display: 'flex',
    flexWrap: 'wrap',
  }),
);

export const VenueTabContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    background: theme?.palette.primary.contrast,
    borderRadius: theme?.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
  }),
);

export const VenueSearchContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    paddingTop: theme?.spacing(1),
  }),
);
