import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';

import {
  TotalsCell,
  VenueNameCell,
  ShowsCountCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import {
  formatNumberToCurrency,
  formatNumberToPercentage,
  formatTotalsToCurrencyOrDash,
} from '@utils/numberHelpers';
import { FinancesHeadliners, FinancesHeadlinersEventOffer } from '@types';

const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 178,
  maxWidth: 248,
};

const dealTypeColumn = {
  field: 'dealType',
  headerName: 'Deal Type',
  flex: 0.125,
  minWidth: 102,
};

const localGuaranteeColumn = {
  field: 'localGuarantee',
  headerName: 'Local Guarantee',
  flex: 0.125,
  minWidth: 108,
};

const localDealRateColumn = {
  field: 'localDealRate',
  headerName: 'Local Deal Rate',
  flex: 0.125,
  minWidth: 108,
};

const forecastedBonusColumn = {
  field: 'forecastedBonus',
  headerName: 'Forecasted Bonus',
  flex: 0.125,
  minWidth: 108,
};

const selloutBonusColumn = {
  field: 'selloutBonus',
  headerName: 'Sellout Bonus',
  flex: 0.125,
  minWidth: 99,
};

const artistReimbursementColumn = {
  field: 'artistReimbursement',
  headerName: 'Artist Reimbursement',
  flex: 0.14,
  minWidth: 140,
};

type HeadlinersColumns = GridColDef<FinancesHeadliners>[];

export const headlinersColumns: HeadlinersColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ExpandButtonCell,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell>{row.name}</TextWrapPrettyCell>
        <ShowsCountCell count={row.eventOffers.length} total={row.showsCount} />
      </LeftPinnedCell>
    ),
  },
  dealTypeColumn,
  {
    ...localGuaranteeColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.localGuarantee),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name={localGuaranteeColumn.headerName} />
    ),
  },
  localDealRateColumn,
  {
    ...forecastedBonusColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.forecastedBonus),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name={forecastedBonusColumn.headerName} />
    ),
  },
  {
    ...selloutBonusColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.selloutBonus),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name={selloutBonusColumn.headerName} />
    ),
  },
  {
    ...artistReimbursementColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.artistReimbursement),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name={artistReimbursementColumn.headerName} />
    ),
  },
];

type HeadlinersEventOfferColumns = GridColDef<FinancesHeadlinersEventOffer>[];

export const headlinersEventOfferColumns: HeadlinersEventOfferColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          eventId={row.eventId}
          offerId={row.offerId}
          settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    maxWidth: 10,
  },
  dealTypeColumn,
  {
    ...localGuaranteeColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.localGuarantee),
  },
  {
    ...localDealRateColumn,
    valueGetter: ({ row }) => formatNumberToPercentage(row.localDealRate),
  },
  {
    ...forecastedBonusColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.forecastedBonus),
  },
  {
    ...selloutBonusColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.selloutBonus),
  },
  {
    ...artistReimbursementColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.artistReimbursement),
  },
];
