import { useContext, useMemo } from 'react';
import { ModelingContainer, SyncButton, TaskPaneHeader } from '@components';
import { useQuery } from '@apollo/client';
import { GET_TOUR_AND_OFFER_ONLY } from '@gql/queries/tours';
import { EnvStatusContext } from '@providers';
import { useAegModeling } from '@hooks';
import { TaskPanePaperContainer } from './TaskPane.styled';

export const ModelingView = () => {
  const { syncModelingWorkbook } = useAegModeling();
  const { envStatus } = useContext(EnvStatusContext);
  const { offerId, tourId } = envStatus;

  const {
    data,
    error,
    loading,
  } = useQuery(GET_TOUR_AND_OFFER_ONLY, {
    variables: { tourId: tourId ?? '', offerId: offerId ?? '' },
  });

  const [tour, offer] = useMemo(() => {
    if (data?.tour?.offers) {
      return [data.tour, data.tour.offers[0]];
    }
    return [];
  }, [data]);

  return loading ? null : (
    <>
      <TaskPaneHeader
        loading={loading}
        tourName={tour?.name}
        offerName={offer?.name}
        offerStatus={offer?.status}
        actions={
          <SyncButton syncFn={syncModelingWorkbook}/>
        }
      >
      </TaskPaneHeader>
      <TaskPanePaperContainer>
        <ModelingContainer/>
      </TaskPanePaperContainer>
    </>
  );
};
