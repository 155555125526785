import { graphql } from '@gql/types';

export const BUNDLES_PAGE_FRAGMENT = graphql(`
  fragment BundlesPageFields on Bundle {
    id
    name
    headliners {
      artist {
        id
        name
      }
    }
    team {
      buyers {
        id
        name
      }
    }
    company {
      id
      name
    }
    status
    startDate
  }
`);

export const EVENT_FRAGMENT = graphql(`
  fragment EventFields on Event {
    id
    status
    isActive
    date
    sellableCapacity
    subledgerId
    subledgerStatus
    subledgerPayload
    primaryBuyer {
      id
      name
    }
    venue {
      id
      name
      city
      stateCode
      timezone
    }
    space {
      id
      name
      maxCapacity
    }
    shows {
      id
      name
      showDateTime
    }
  }
`);

export const GET_BUNDLES_PAGE = graphql(`
  query GetBundlesPage($page: Int, $limit: Int, $bundleSearch: BundleSearch) {
    bundles(page: $page, limit: $limit, bundleSearch: $bundleSearch) {
      ...BundlesPageFields
    }
  }
`);

export const SEARCH_BUNDLES = graphql(`
  query SearchBundles($searchTerm: String!) {
    searchBundles(searchTerm: $searchTerm) {
      id
      name
    }
  }
`);

export const GET_BUNDLE = graphql(`
  query GetBundle($bundleId: ID!) {
    bundle(bundleId: $bundleId) {
      id
      name
      status
      primaryOfferId
      tourNotes
      headliners {
        artist {
          id
          name
        }
        agency {
          id
          name
        }
        agents {
          id
          name
          email
          phone
        }
      }
      company {
        id
        name
      }
      offers {
        id
        name
        status
        crossed
        offerEventRouting {
          event {
            ...EventFields
          }
          isActive
        }
      }
      team {
        buyers {
          id
          name
          email
        }
        operators {
          id
          name
          email
        }
        viewOnlyUsers {
          id
          name
          email
        }
      }
      genres {
        id
        name
      }
    }
  }
`);
