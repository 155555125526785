/* eslint-disable max-lines-per-function */
import { CloudQueue, Save, MailOutline } from '@mui/icons-material';
import { AegResourceTypes, EventStatus, UserPermissions } from '@types';
import { GET_NOS_LAUNCH_URL, GET_NOS_SETTLEMENT_DOWNLOAD_URL } from '@gql/queries/nosSettlements';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';
import { useLazyQuery } from '@apollo/client';
import { DialogDispatchContext } from '@providers';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { Venue } from '@gql/types/graphql';
import { getMarketDisplayName } from '@utils/venueHelpers';
import featureFlags from '@utils/featureFlags';
import { FeatureGate, GridActionsCellItemWrapper } from '@components';
import { NOSVersionRow } from './NOSVersionsTable.models';
import { DOWNLOAD_NOS_SETTLEMENT_CONFIRMATION_DIALOG } from '../EventsList/EventList.constants';
import { ProtectedComponent } from '../ProtectedComponent/ProtectedComponent';

export interface SendNosEmailProps {
  nosSettlementId: string;
  eventId: string;
  version?: number;
  currency?: string;
}

export const NOSVersionTableActions = (
  { row }: GridRowParams<NOSVersionRow>,
  sendNOSEmail: (data: SendNosEmailProps) => void,
): JSX.Element[] => {
  const [getNOSLaunchUrl] = useLazyQuery(GET_NOS_LAUNCH_URL);
  const [getNOSDownloadUrl] = useLazyQuery(GET_NOS_SETTLEMENT_DOWNLOAD_URL);
  const setDialog = useContext(DialogDispatchContext);

  const launchExcel = async () => {
    if (row.id) {
      const nosLaunchUrlResponse = await getNOSLaunchUrl({ variables: { nosSettlementId: row.id } });
      window.location.assign(`ms-excel:ofe|u|${nosLaunchUrlResponse.data?.getNOSSettlementLaunchUrl?.url || ''}`);
    }
  };

  const downloadExcel = async (): Promise<void> => {
    if (row.tourId && row.venue) {
      const date = row.event?.date;
      const { venue: { timezone } } = row;
      const dateObject = dayjs(date);

      const timezoneAwareEventDate = (timezone) ? dateObject.tz(timezone) : dateObject;
      const formattedEventDate = timezoneAwareEventDate.format('MMDDYY');

      // remove spaces, underscores, and commas from market
      const market = getMarketDisplayName(row.venue as Venue);
      const formattedMarketName = market.replace(/[\s_]/g, '').replace(',', ' ');

      const tourName = row.event?.tourInformation?.tourName;

      // Get Download URL
      const { data: nosLaunchUrlResponse } = await getNOSDownloadUrl({ variables: { nosSettlementId: row.id } });
      const downloadUrl = nosLaunchUrlResponse?.getNOSSettlementDownloadUrl?.url;

      if (downloadUrl && tourName) {
        const workbookUrlResponse = await fetch(downloadUrl, {
          mode: 'cors',
        });
        const blob = await workbookUrlResponse.blob();
        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = `${tourName} - ${formattedEventDate} - ${formattedMarketName} - Settlement.xlsm`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectURL);
      }
    }
  };

  const actions = [];

  const {
    id,
    isPrimary,
  } = row;

  const status = row.event?.status;
  const isSettledInOldElvis = row.event?.isSettledInOldElvis;

  const canLaunchNosWorkbook = (status === EventStatus.Canceled
    || status === EventStatus.Confirmed
    || status === EventStatus.NOSSettled
    || status === EventStatus.AccountingSettled
    || status === EventStatus.CanceledSettled)
    && !isSettledInOldElvis;
  const canSaveNosWorkbook = (status === EventStatus.NOSSettled
    || status === EventStatus.AccountingSettled
    || status === EventStatus.CanceledSettled
  );
  const canSendNosEmail = (status === EventStatus.NOSSettled
    || status === EventStatus.AccountingSettled
  );

  if (canLaunchNosWorkbook) {
    actions.push(
      <GridActionsCellItemWrapper key={`launch-nos-${id}`} showInMenu>
        <ProtectedComponent checkPermission={{
          permission: UserPermissions.ViewNos,
          resourceType: AegResourceTypes.Tour,
          resourceId: row.tourId,
        }}>
          <GridActionsCellItem
            key={id}
            showInMenu
            label='Launch NOS'
            onClick={() => {
              void launchExcel();
            }}
            icon={<CloudQueue />}
          />
        </ProtectedComponent>
      </GridActionsCellItemWrapper>,
    );
  }

  const handleDownloadNosSettlement = () => {
    const { TITLES, SUBMIT, CANCEL } = DOWNLOAD_NOS_SETTLEMENT_CONFIRMATION_DIALOG();

    setDialog({
      titles: TITLES,
      submit: {
        text: SUBMIT,
        action: () => {
          setDialog(null);
          void downloadExcel();
        },
      },
      cancel: {
        text: CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  if (canSaveNosWorkbook) {
    actions.push(
      <GridActionsCellItemWrapper key={`save-workbook-copy-${id}`} showInMenu>
        <ProtectedComponent checkPermission={{
          permission: UserPermissions.ViewNos,
          resourceType: AegResourceTypes.Tour,
          resourceId: row.tourId,
        }}>
          <GridActionsCellItem
            key={id}
            showInMenu
            label='Save NOS Copy'
            onClick={() => {
              void handleDownloadNosSettlement();
            }}
            icon={<Save />}
            data-testid="save-workbook-copy"
          />
        </ProtectedComponent>
      </GridActionsCellItemWrapper>,
    );
  }

  if (canLaunchNosWorkbook) {
    actions.push(
      <GridActionsCellItemWrapper key={`send-nos-email-${id}`} showInMenu>
        <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_EMAIL}>
          <ProtectedComponent checkPermission={{
            permission: UserPermissions.ViewNos,
            resourceType: AegResourceTypes.Tour,
            resourceId: row.tourId,
          }}>
            <GridActionsCellItem
              disabled={!(canSendNosEmail && isPrimary)}
              key={id}
              showInMenu
              label='Send NOS Email'
              onClick={() => sendNOSEmail({
                nosSettlementId: id,
                eventId: row.event?.id as string,
                version: row.version ?? undefined,
                currency: row.event?.localCurrency ?? undefined,
              })}
              icon={<MailOutline />}
              data-testid="send-nos-email"
            />
          </ProtectedComponent>
        </FeatureGate>
      </GridActionsCellItemWrapper>,
    );
  }

  return actions;
};
