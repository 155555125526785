/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable max-lines-per-function */
import dayjs from 'dayjs';
import {
  Add,
  Save,
  Cloud,
} from '@mui/icons-material';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';

import {
  EventStatus,
  UserPermissions,
  AegResourceTypes,
} from '@types';
import {
  CAN_CREATE_NOS_VERSION,
  GET_NOS_SETTLEMENT_LAUNCH_URL,
  GET_NOS_SETTLEMENT_DOWNLOAD_URL,
  GET_PRIMARY_NOS_SETTLEMENT_LAUNCH_URL,
} from '@gql/queries/nosSettlements';
import featureFlags from '@utils/featureFlags';
import { GET_TOUR_NAME } from '@gql/queries/tours';
import { getExcelWorkbookLaunchUrl } from '@utils/stringHelpers';
import { CREATE_NOS_WORKBOOK } from '@gql/mutations/nosSettlements';
import { DialogDispatchContext, NotificationDispatchContext } from '@providers';

import { SnackbarType } from '../Snackbar/Snackbar';
import { ShowsGridData } from '../shared/ShowTable/ShowTable.types';
import { ProtectedComponent } from '../ProtectedComponent/ProtectedComponent';
import { DOWNLOAD_NOS_SETTLEMENT_CONFIRMATION_DIALOG } from './EventList.constants';
import { ConditionalTooltip } from '../shared/ConditionalTooltip/ConditionalTooltip';
import { FeatureGate, GridActionsCellItemWrapper, PrerequisitesComponent } from '../shared';

export const EventListTableActions = ({ row }: GridRowParams<ShowsGridData>): JSX.Element[] => {
  const {
    id, status, isSettledInOldElvis, workbookFileId,
  } = row;
  const canLaunchNosWorkbook = (status === EventStatus.Canceled
    || status === EventStatus.Confirmed
    || status === EventStatus.NOSSettled
    || status === EventStatus.AccountingSettled
    || status === EventStatus.CanceledSettled)
    && !isSettledInOldElvis;
  const canSaveNosWorkbook = ((status === EventStatus.NOSSettled
    || status === EventStatus.AccountingSettled
    || status === EventStatus.CanceledSettled)
  );

  const setNotification = useContext(NotificationDispatchContext);
  const { id: tourId } = useParams();
  const setDialog = useContext(DialogDispatchContext);
  const [getNOSSettlementLaunchUrl, { loading: getLaunchUrlLoading }] = useLazyQuery(GET_NOS_SETTLEMENT_LAUNCH_URL, {
    onError() {
      const snackbarMessage = (
        <>
          <span>We were unable to launch the NOS version workbook.</span>
          <br /><br />
          <span>To resolve the issue, please launch the workbook manually.</span>
        </>
      );

      setNotification({
        type: SnackbarType.ERROR,
        text: snackbarMessage,
      });
    },
  });

  const [createNOSWorkbook, { loading: createWorkbookLoading }] = useMutation(CREATE_NOS_WORKBOOK, {
    onCompleted: async (data) => {
      const response = await getNOSSettlementLaunchUrl({
        variables: {
          nosSettlementId: data.createNOSSettlement?.id || '',
        },
      });
      window.location.assign(getExcelWorkbookLaunchUrl(response.data?.getNOSSettlementLaunchUrl?.url as string));
    },
    onError() {
      const snackbarMessage = (
        <>
          <span>We were unable to create a new NOS version.</span>
          <br/><br/>
          <span>To resolve the issue, please try again.</span>
        </>
      );

      setNotification({
        type: SnackbarType.ERROR,
        text: snackbarMessage,
      });
    },
  });
  const [getPrimaryNOSSettlementLaunchUrl, { loading: getPrimaryLaunchUrlLoading },
  ] = useLazyQuery(GET_PRIMARY_NOS_SETTLEMENT_LAUNCH_URL, {
    onError() {
      const snackbarMessage = (
        <>
          <span>We were unable to launch the NOS version workbook.</span>
          <br /><br />
          <span>To resolve the issue, please launch the workbook manually.</span>
        </>
      );

      setNotification({
        type: SnackbarType.ERROR,
        text: snackbarMessage,
      });
    },
  });
  const [canCreateNOSVersionQuery] = useLazyQuery(CAN_CREATE_NOS_VERSION);
  const [nosSettlementQuery] = useLazyQuery(GET_NOS_SETTLEMENT_DOWNLOAD_URL);
  const [tourQuery] = useLazyQuery(GET_TOUR_NAME);

  const createNewNosLoading = getLaunchUrlLoading || createWorkbookLoading;

  const downloadExcel = async (params: ShowsGridData): Promise<void> => {
    if (params.id && tourId) {
      // TODO: Can we pull from TourInformation under event?
      const { data: tourData } = await tourQuery({ variables: { tourId } });
      // Get Download URL
      const { data: nosLaunchUrlResponse } = await nosSettlementQuery({ variables: { nosSettlementId: params.id } });
      const downloadUrl = nosLaunchUrlResponse?.getNOSSettlementDownloadUrl?.url;

      const tourName = tourData?.tour?.name ?? '';
      const eventDate = dayjs(params.firstEventDate);
      const timezoneAwareEventDate = (params.timezone) ? eventDate.tz(params.timezone) : eventDate;
      const formattedEventDate = timezoneAwareEventDate.format('MMDDYY');

      // remove spaces, underscores, and commas from market
      const market = params.market.replace(/[\s_]/g, '').replace(',', ' ');
      if (downloadUrl) {
        await fetch(downloadUrl, {
          mode: 'cors',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectURL;
            link.download = `${tourName} - ${formattedEventDate} - ${market} - Settlement.xlsm`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectURL);
          });
      }
    }
  };

  const createWorkbook = async (eventId: string) => {
    if (!eventId || !tourId) return;

    await createNOSWorkbook({
      variables: {
        tourId,
        eventId,
      },
    });
  };

  const launchPrimaryNOS = async (eventId: string) => {
    if (!eventId || !tourId) return;

    const response = await getPrimaryNOSSettlementLaunchUrl({
      variables: { eventId },
    });

    const primaryNOSLaunch = response.data?.getPrimaryNOSSettlementLaunchUrl;

    if (primaryNOSLaunch?.url) {
      window.location.assign(getExcelWorkbookLaunchUrl(primaryNOSLaunch.url));
    }

    if (primaryNOSLaunch === null) {
      await createNOSWorkbook({
        variables: {
          tourId,
          eventId,
        },
      });
    }
  };

  const validateCanCreateNOSVersion = async () => {
    const { data: canCreateNOSVersion } = await canCreateNOSVersionQuery({
      variables: { eventId: id },
    });
    return Boolean(canCreateNOSVersion?.canCreateNOSVersion);
  };

  const handleDownloadNosSettlement = (params: ShowsGridData) => {
    const { TITLES, SUBMIT, CANCEL } = DOWNLOAD_NOS_SETTLEMENT_CONFIRMATION_DIALOG();

    setDialog({
      titles: TITLES,
      submit: {
        text: SUBMIT,
        action: () => {
          setDialog(null);
          void downloadExcel(params);
        },
      },
      cancel: {
        text: CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  const actions = [];

  if (canLaunchNosWorkbook) {
    // Launch Primary NOS Settlement action
    actions.push(
      <GridActionsCellItemWrapper key={`launch-primary-nos-${id}`} showInMenu>
        <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_VERSIONING}>
          <ProtectedComponent checkPermission={{
            permission: UserPermissions.ViewNos,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId,
          }}>
            <GridActionsCellItem
              key={id}
              showInMenu
              label='Launch Primary NOS'
              onClick={() => {
                void launchPrimaryNOS(id);
              }}
              icon={getPrimaryLaunchUrlLoading
                ? <CircularProgress size={24} color="inherit" data-testid="progress-icon" />
                : <Cloud />}
              disabled={getPrimaryLaunchUrlLoading}
              data-testid='launch-primary-nos-action'
            />
          </ProtectedComponent>
        </FeatureGate>
      </GridActionsCellItemWrapper>,
    );

    // Create New NOS action
    actions.push(
      <GridActionsCellItemWrapper key={`create-new-nos-${id}`} showInMenu>
        <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_VERSIONING}>
          <ProtectedComponent checkPermission={{
            permission: UserPermissions.ViewNos,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId,
          }}>
            <PrerequisitesComponent
              prerequisites={[validateCanCreateNOSVersion]}
              render={(requirementsMet, requirementsLoading) => (
                <ConditionalTooltip
                  title='You are able to generate up to 5 documents.'
                  display={!requirementsMet}
                >
                  <GridActionsCellItem
                    key={id}
                    showInMenu
                    // todo: closeMenuOnClick will works once we upgrade @mui/x-data-grid-pro
                    // closeMenuOnClick={closeMenuOnClick}
                    label='Create New NOS'
                    onClick={() => {
                      void createWorkbook(id);
                    }}
                    disabled={!requirementsMet || createNewNosLoading || requirementsLoading}
                    icon={(createNewNosLoading || requirementsLoading)
                      ? <CircularProgress size={24} color="inherit" data-testid="progress-icon" />
                      : <Add />}
                    data-testid='create-new-nos-action'
                  />
                </ConditionalTooltip>
              )}
            />
          </ProtectedComponent>
        </FeatureGate>
      </GridActionsCellItemWrapper>,
    );
  }

  if (canSaveNosWorkbook && workbookFileId) {
    actions.push(
      <GridActionsCellItemWrapper key={`save-workbook-copy-${id}`} showInMenu>
        <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_VERSIONING} disableFlag>
          <ProtectedComponent checkPermission={{
            permission: UserPermissions.ViewNos,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId,
          }}>
            <GridActionsCellItem
              key={id}
              showInMenu
              label='Save Workbook Copy'
              onClick={() => {
                void handleDownloadNosSettlement(row);
              }}
              icon={<Save />}
              data-testid='save-workbook-copy'
            />
          </ProtectedComponent>
        </FeatureGate>
      </GridActionsCellItemWrapper>,
    );
  }

  return actions;
};
