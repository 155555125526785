import React from 'react';
import { Typography } from '@mui/material';

import { Venue } from '@gql/types/graphql';
import { StyledVenueName } from './VenueNameCell.styled';

interface VenueNameProps {
  venue: Venue;
}

export function VenueNameCell({ venue }: VenueNameProps) {
  return (
    <StyledVenueName>
      <Typography variant="body2">
        {venue.name}
      </Typography>
      <Typography variant="caption-small" color="text.secondary">
        {venue.city}, {venue.stateCode}
      </Typography>
    </StyledVenueName>
  );
}
