import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';

import {
  TotalsCell,
  VenueNameCell,
  ShowsCountCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import {
  formatNumberToCurrency,
  formatNumberToPercentage,
  formatTotalsToCurrencyOrDash,
} from '@utils/numberHelpers';
import { FinancesVariableCosts, FinancesVariableCostsEventOffer } from '@types';

const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 178,
  maxWidth: 248,
};

const budgetPercentageColumn = {
  field: 'budgetPercentage',
  headerName: 'Budget (%)',
  flex: 0.09,
  minWidth: 89,
};

const internalPercentageColumn = {
  field: 'internalPercentage',
  headerName: 'Internal (%)',
  flex: 0.09,
  minWidth: 89,
};

const formulaColumn = {
  field: 'formula',
  headerName: 'Formula',
  flex: 0.09,
  minWidth: 92,
};

const percentageNetGrossReceiptColumn = {
  field: 'percentageNetGrossReceipt',
  headerName: '% Net Gross Receipt',
  flex: 0.12,
  minWidth: 89,
};

const minColumn = {
  field: 'min',
  headerName: 'Min',
  flex: 0.09,
  minWidth: 64,
};

const maxColumn = {
  field: 'max',
  headerName: 'Max',
  flex: 0.09,
  minWidth: 64,
};

const budgetColumn = {
  field: 'budget',
  headerName: 'Budget',
  flex: 0.09,
  minWidth: 88,
};

const internalColumn = {
  field: 'internal',
  headerName: 'Internal',
  flex: 0.09,
  minWidth: 89,
};

const notesColumn = {
  field: 'notes',
  headerName: 'Notes',
  flex: 0.3,
  minWidth: 170,
};

type VaribleCostsColumns = GridColDef<FinancesVariableCosts>[];

export const variableCostsColumns: VaribleCostsColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ExpandButtonCell,
  },
  {
    field: 'name',
    ...leftPinnedColumn,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell>{row.name}</TextWrapPrettyCell>
        <ShowsCountCell count={row.eventOffers.length} total={row.showsCount} />
      </LeftPinnedCell>
    ),
  },
  budgetPercentageColumn,
  internalPercentageColumn,
  formulaColumn,
  percentageNetGrossReceiptColumn,
  minColumn,
  maxColumn,
  {
    ...budgetColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.budget),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name='Budget' />
    ),
  },
  {
    ...internalColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.internal),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name='Internal' />
    ),
  },
  notesColumn,
];

type VariableCostsEventOfferColumns = GridColDef<FinancesVariableCostsEventOffer>[];

export const variableCostsEventOfferColumns: VariableCostsEventOfferColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          eventId={row.eventId}
          offerId={row.offerId}
          settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  {
    ...budgetPercentageColumn,
    valueGetter: ({ row }) => formatNumberToPercentage(row.budgetPercentage),
  },
  {
    ...internalPercentageColumn,
    valueGetter: ({ row }) => formatNumberToPercentage(row.internalPercentage),
  },
  {
    ...formulaColumn,
    valueGetter: ({ row }) => row.feeItem.formula,
  },
  {
    ...percentageNetGrossReceiptColumn,
    valueGetter: ({ row }) => formatNumberToPercentage(row.percentageNetGrossReceipt, 0),
  },
  {
    ...minColumn,
    valueGetter: ({ row }) => row.feeItem.min?.toLocaleString(),
  },
  {
    ...maxColumn,
    valueGetter: ({ row }) => row.feeItem.max?.toLocaleString(),
  },
  {
    ...budgetColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.budget),
  },
  {
    ...internalColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.internal),
  },
  {
    ...notesColumn,
    valueGetter: ({ row }) => row.feeItem.note,
  },
];
