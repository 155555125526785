/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
import {
  DetailsMetadataItem,
  SelectWithPrimary,
  SelectWithPrimaryOptions,
  ProtectedComponent,
  StatusChip,
  MetadataObject,
  KebabPopperMenu,
  ExcelButton,
} from '@components';
import {
  Grid,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { Artist, Offer } from '@gql/types/graphql';
import { useMutation } from '@apollo/client';
import { LAUNCH_EXCEL } from '@gql/mutations/tours';
import { AegResourceTypes, ExcelWorkbookLaunchMode, UserPermissions } from '@types';
import { getConfigurationTypeLabel } from '@utils/configurationTypeHelpers';
import { getExcelWorkbookLaunchUrl } from '@utils/stringHelpers';
import {
  ExcelButtonContainer,
  MetadataGrid,
  OfferButtons,
  OfferMenuContainer,
  OfferSelectContainer,
} from './TourSummaryHeader.styled';

export interface TourSummaryHeaderProps {
  tourId: string;
  offer: Offer | null;
  offerItems: SelectWithPrimaryOptions[];
  offerSelectValue?: string;
  offerChange: (offerId: string) => void;
  handleCloneOffer: () => void;
  handleEditOffer: () => void;
  isOfferDraft: boolean;
  isPrimaryOfferDisabled: boolean;
  updatePrimaryOffer: (offerId: string, name: string) => Promise<void>;
}

export const TourSummaryHeader: React.FC<TourSummaryHeaderProps> = ({
  tourId,
  offer,
  offerSelectValue,
  offerItems,
  offerChange,
  handleEditOffer,
  handleCloneOffer,
  isOfferDraft,
  isPrimaryOfferDisabled,
  updatePrimaryOffer,
}) => {
  const [launchExcelWorkbook, { loading }] = useMutation(LAUNCH_EXCEL);

  const launchExcel = async () => {
    if (offer) {
      const response = await launchExcelWorkbook({
        variables: {
          launchExcel: {
            tourId,
            offerId: offer.id as string,
            mode: ExcelWorkbookLaunchMode.MODELING,
          },
        },
      });
      window.location.href = getExcelWorkbookLaunchUrl(response.data?.launchExcelWorkbook?.url as string);
    }
  };

  const setPrimaryOffer = async () => {
    const offerId = offer?.id as string;
    const name = offer?.name as string;
    await updatePrimaryOffer(offerId, name);
  };

  const getCrossedDisplayLabel = (): string => {
    if (offer?.isCrossed !== undefined) {
      return offer?.isCrossed === true ? 'Yes' : 'No';
    }
    return '';
  };

  const metadata: MetadataObject[] = [];
  metadata.push({
    title: 'Offer Status',
    element: <StatusChip status={offer?.status || 'Draft'}/>,
  });
  metadata.push({
    title: 'Supporting Artist',
    value:
      offer && offer.supports?.length
        ? offer.supports
          .filter((support): support is Artist => support !== null)
          .map((support) => support.name || '')
        : [],
  });
  metadata.push({
    title: 'Configuration',
    value: getConfigurationTypeLabel(offer?.configuration),
  });
  metadata.push({
    title: 'Crossed',
    value: String(getCrossedDisplayLabel()),
  });
  metadata.push({
    title: 'Trucks',
    value: (offer && offer.numberOfTrucks?.toString()) || '0',
  });

  const metadataRowItems = metadata.map((metadataItem, index) => (
    <DetailsMetadataItem
      key={index}
      metadata={metadataItem}
      first={index === 0}
    />
  ));

  return (
    <Grid container>
      <Grid item xs={2.5} data-testid="offer-dropdown-container">
        <OfferSelectContainer>
          <SelectWithPrimary
            value={
              offerItems.find((item) => item.value === offerSelectValue)
                ?.value || undefined
            }
            items={offerItems}
            label="Offers"
            onChange={offerChange}
          ></SelectWithPrimary>
        </OfferSelectContainer>
      </Grid>
      <MetadataGrid
        item
        xs={true}
        container
        spacing={5}
        data-testid="offer-metadata"
      >
        {metadataRowItems}
      </MetadataGrid>
      <OfferButtons>
        <ExcelButtonContainer>
          <ExcelButton
            launchExcel={launchExcel}
            loading={loading}
          />
        </ExcelButtonContainer>
        <ProtectedComponent
          checkPermission={{
            permission: UserPermissions.UpdateTour,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId,
          }}
        >
          <OfferMenuContainer>
            <KebabPopperMenu>
              <MenuItem onClick={handleEditOffer}>
                Edit Offer
              </MenuItem>
              <MenuItem
                onClick={handleCloneOffer}
                disabled={!isOfferDraft}
              >
                Duplicate Offer
              </MenuItem>
              <MenuItem
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={setPrimaryOffer}
                disabled={isPrimaryOfferDisabled}
                data-testid="select-primary-button"
              >
                Set as Primary
              </MenuItem>
            </KebabPopperMenu>
          </OfferMenuContainer>
        </ProtectedComponent>
      </OfferButtons>
    </Grid>
  );
};
