import React from 'react';
import { MenuItem } from '@mui/material';

import { ValueTypeCellContainer } from './ValueTypeCell.styled';
import { SelectCell, TextFieldCell } from '../Cells.styled';

interface ValueTypeCellProps {
  value?: string;
  defaultValue?: string;
  type?: string;
  options: {
    label: string;
    value: string;
  }[];
  disabled?: boolean;
}

export function ValueTypeCell({
  value, type, options, disabled, defaultValue,
}: ValueTypeCellProps) {
  return (
    <ValueTypeCellContainer>
      <TextFieldCell
        variant="standard"
        placeholder='Enter a value'
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <SelectCell
        variant="standard"
        displayEmpty
        value={type}
        disabled={disabled}
      >
        <MenuItem disabled value="">
          Enter amount
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectCell>
    </ValueTypeCellContainer>
  );
}
