/* eslint-disable no-console */
/* eslint-disable max-depth */
import { EnvStatusContext, NotificationDispatchContext } from '@providers';
import { useContext } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { SnackbarType } from '@components';
import { useNavigateOnError, NavigateErrorType } from '@hooks';
import {
  WORKBOOK_ORIGINAL_PARENT_DIR,
  WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER,
  WORKBOOK_ORIGINAL_PARENT_DIR_PATH,
  WORKBOOK_ORIGINAL_FILE_DEVIATION_ERROR_MSG,
} from '@utils/excel/shared/constants';
import {
  LATERAL_SUM_SHEET_NAME,
  OVERHEAD_COSTS_SHEET_NAME,
  QUICK_SUM_SHEET_NAME,
  SHOW_DETAIL_SHEET_NAME,
  SUMMARY_SHEET_NAME,
  APPROVAL_EMAIL_SHEET_NAME,
} from '@utils/excel/modeling/constants';

/* eslint-disable max-lines-per-function */
export enum ContextType {
  MarketSheet,
}

export enum ExcelCalcMode {
  automatic = 'Automatic',
  manual = 'Manual',
}

/* Expected format Excel uses to populate a table row */
export type TableDataArray = Array<Array<boolean | string | number>>;

export type TableData = {
  name: string;
  data: TableDataArray;
};

type SyncedTableData = {
  syncedTable: Excel.Table;
  tableData: TableDataArray;
  existingTableRange: Excel.Range;
  isBatchInsert: boolean;
  isCleanInsert: boolean;
};

export type NamedRangeAssignment = {
  range: string;
  value?: string;
  format?: string;
};

export type CustomProperty = {
  property: string;
  value: string;
};

export type PopulateTable = {
  sheetName: string;
  tableData: TableData;
  cleanInsert: boolean;
  batchInsert?: boolean;
};

export type PopulateTablesData = {
  tableData: TableData;
  cleanInsert: boolean;
  batchInsert?: boolean;
};

export type ApplyNamedRangeAssignments = {
  sheet: string;
  namedRangeAssignments: NamedRangeAssignment[];
};

type GetValuesFromNamedRanges = {
  sheet: string;
  namedRanges: string[];
};

type DeleteSheet = {
  sheetName: string;
};

type ProtectSheet = {
  sheetName: string;
};

type CopySheet = {
  sheetToCopy: string;
  newSheetName: string;
  customProperties: CustomProperty[];
};

type UpdateTableName = {
  sheetName: string;
  currTableName: string;
  updatedTableName: string;
};

type RemoveRowByValue = {
  sheetName: string; // Have to get sheet before getting table
  tableName: string;
  headerName: string;
  value: string;
};

type FilterData = {
  columnIndex: number;
  filterCriteria: Excel.FilterCriteria;
};

type SheetWithCustomProps = {
  sheet: Excel.Worksheet;
  customSheetProps: Excel.WorksheetCustomPropertyCollection;
};

type NamedRangeObj = {
  name: string;
  range?: Excel.Range;
};

let sheetRenameListener: OfficeExtension.EventHandlerResult<Excel.WorksheetNameChangedEventArgs> | undefined;

/**
 * This will receive an excel formula in the format `=<sheet-name>!<some-cell>`, and extract the `sheet-name` portion.
 * This is used in cells that show data from other sheets, like the "Show Details" sheet, and can be
 * used to identify which sheet this particular cell referes to.
 *
 * By Excel's rules, <sheet-name> can either be `Name` (with no spaces) or `'Name with Spaces'` (with single quotes).
 * Also, <some-cell> can be either a direct reference (like `A1`) or a named cell (like `Status`),
 * or even a range (like `A:A`).
 * @param formula A string containing an Excel formula in the format of `=<sheet-name>!<some-cell>`
 * @returns The name of the sheet the formula references
 */
const extractReferencingSheetFromFormula = (formula?: string): string | null => {
  const regex = /='?([^']+)'?!.+/;

  const matches = formula?.match(regex);

  if (matches?.length === 2) {
    return matches[1];
  }

  return null;
};

export function useExcel() {
  const appInsights = useAppInsightsContext();
  const navigateOnError = useNavigateOnError();
  const setNotification = useContext(NotificationDispatchContext);
  const { envStatus } = useContext(EnvStatusContext);
  const { isAutomationEnabled } = envStatus;

  const saveWorkbook = async () => {
    console.time('saveWorkbook');
    try {
      await Excel.run(async (context) => {
        context.workbook.save();
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in saveWorkbook:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in saveWorkbook:', err);
      }
      throw err;
    } finally {
      console.timeEnd('saveWorkbook');
    }
  };

  const setCalculationMode = async (calcMode: ExcelCalcMode) => {
    console.time('setCalculationMode');
    try {
      await Excel.run(async (context) => {
        if (calcMode === ExcelCalcMode.manual) {
          context.application.calculationMode = Excel.CalculationMode.manual;
        } else if (calcMode === ExcelCalcMode.automatic) {
          context.application.calculationMode = Excel.CalculationMode.automatic;
        }

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in setCalculationMode:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in setCalculationMode:', err);
      }
      throw err;
    } finally {
      console.timeEnd('setCalculationMode');
    }
  };

  /**
   * Reorder sheets in the workbook
   * @param orderedEvents array of eventIds in correct order
   */
  const reorderWorksheets = async (orderedEvents: (string | null)[]) => {
    console.time('reorderWorksheets');
    try {
      await Excel.run(async (context) => {
        const REQUIRED_SHEETS = [
          SUMMARY_SHEET_NAME,
          SHOW_DETAIL_SHEET_NAME,
          OVERHEAD_COSTS_SHEET_NAME,
          QUICK_SUM_SHEET_NAME,
          LATERAL_SUM_SHEET_NAME,
          APPROVAL_EMAIL_SHEET_NAME,
        ];
        const sheets = context.workbook.worksheets;
        sheets.load('items');
        await context.sync();

        // Load and sync so that we know the contextId of each sheet
        const sheetsWithProps: SheetWithCustomProps[] = [];
        sheets.items.forEach((item) => {
          const sheet = item;
          if (sheet.visibility === Excel.SheetVisibility.hidden) {
            return;
          }
          const customSheetProps = sheet.customProperties;
          customSheetProps.load(['key', 'value']);
          sheetsWithProps.push({
            sheet,
            customSheetProps,
          });
        });
        await context.sync();

        // Create a map of sheets by their contextId
        const mapOfSheetsById = <Record<string, SheetWithCustomProps>>{};
        const requiredSheets: Excel.Worksheet[] = [];
        const sideSheets: Excel.Worksheet[] = [];
        sheetsWithProps.forEach((sheetWithProps) => {
          if (!sheetWithProps?.customSheetProps?.items) {
            sideSheets.push(sheetWithProps.sheet);
            return;
          }
          const contextId = sheetWithProps.customSheetProps.items.find((prop) => prop.key === 'contextId')?.value;
          if (!contextId) {
            if (REQUIRED_SHEETS.includes(sheetWithProps.sheet.name)) {
              requiredSheets.push(sheetWithProps.sheet);
            } else {
              sideSheets.push(sheetWithProps.sheet);
            }
            return;
          }
          mapOfSheetsById[contextId] = sheetWithProps;
        });
        const numRequiredSheets = requiredSheets.length;

        requiredSheets.forEach((sheet, index) => {
          const requiredSheet = sheet;
          requiredSheet.position = index;
        });

        sideSheets.forEach((sheet, index) => {
          const sideSheet = sheet;
          sideSheet.position = numRequiredSheets + index;
        });

        const numNonMarketSheets = numRequiredSheets + sideSheets.length;
        // Reorder sheets based on matching the contextIds to orderedEvent ids
        orderedEvents.forEach((sheetId, index) => {
          if (sheetId && mapOfSheetsById[sheetId]) {
            const desiredPosition = numNonMarketSheets + index;
            mapOfSheetsById[sheetId].sheet.position = desiredPosition;
          }
        });
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in reorderWorksheets:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in reorderWorksheets:', err);
      }
      throw err;
    } finally {
      console.timeEnd('reorderWorksheets');
    }
  };

  const getTableHeaders = async (sheetName: string, tableName: string) => {
    let tableHeaders: string[] = [];

    console.time('getTableHeaders');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItem(tableName);
        table.columns.load('items');
        await context.sync();
        tableHeaders = table.columns.items.map((item) => item.name);
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in getTableHeaders:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in getTableHeaders:', err);
      }
      throw err;
    } finally {
      console.timeEnd('getTableHeaders');
    }

    return tableHeaders;
  };

  const sortTable = async (sheetName: string, tableName: string, sortOrder: Excel.SortField[]) => {
    console.time('sortTable');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const currentTable = sheet.tables.getItem(tableName);
        const sortRange = currentTable.getDataBodyRange();
        sortRange.sort.apply(sortOrder);
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in sortTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in sortTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd('sortTable');
    }
  };

  const tableHasFilters = async (sheetName: string, tableName: string) => {
    let hasFilters = false;

    console.time('tableHasFilters');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItem(tableName);
        table.autoFilter.load();
        await context.sync();
        hasFilters = table.autoFilter.enabled;
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in tableHasFilters:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in tableHasFilters:', err);
      }
      throw err;
    } finally {
      console.timeEnd('tableHasFilters');
    }

    return hasFilters;
  };

  const mapFilterCriteriaToFilterData = (filterCriteria: Excel.FilterCriteria[]): FilterData[] => {
    const filterData: FilterData[] = [];
    filterCriteria.forEach((criteria, i) => {
      const {
        color, criterion1, criterion2, dynamicCriteria, filterOn, icon, operator, subField, values,
      } = criteria;
      if (filterOn && (values || criterion1 || criterion2)) {
        filterData.push({
          columnIndex: i,
          filterCriteria: {
            color,
            criterion1,
            criterion2,
            dynamicCriteria,
            filterOn,
            icon,
            operator,
            subField,
            values,
          },
        });
      }
    });
    return filterData;
  };

  const addFilters = (table: Excel.Table, filterData: FilterData[]) => {
    const range = table.getRange();
    filterData?.forEach((obj) => {
      table.autoFilter.apply(range, obj.columnIndex, obj.filterCriteria);
    });
    const headerRowRange = table.getHeaderRowRange();
    headerRowRange.rowHidden = true;
  };

  const addFilterByValuesToTable = async (
    sheetName: string,
    tableName: string,
    columnIndex: number,
    values: string[],
  ) => {
    console.time('addFilterByValuesToTable');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItem(tableName);

        const filterData = {
          columnIndex,
          filterCriteria: {
            filterOn: Excel.FilterOn.values,
            values,
          },
        };

        addFilters(table, [filterData]);

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in addFilterByValuesToTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in addFilterByValuesToTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd('addFilterByValuesToTable');
    }
  };

  const reapplyFiltersToTable = async (sheetName: string, tableName: string) => {
    console.time('reapplyFiltersToTable');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItem(tableName);
        table.autoFilter.reapply();
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in reapplyFiltersToTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in reapplyFiltersToTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd('reapplyFiltersToTable');
    }
  };

  const removeFiltersOnTable = async (sheetName: string, tableName: string) => {
    console.time('removeFiltersOnTable');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItem(tableName);
        table.autoFilter.remove();
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in removeFiltersOnTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in removeFiltersOnTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd('removeFiltersOnTable');
    }
  };

  const setTabColor = async (sheetName: string, color: string) => {
    console.time('setTabColor');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        if (sheet) {
          sheet.tabColor = color;
        }
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in setTabColor:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in setTabColor:', err);
      }
      throw err;
    } finally {
      console.timeEnd('setTabColor');
    }
  };

  const findCellReferencingSheet = async (
    sheetName: string,
    tableName: string,
    referencedSheet: string,
    columnName: string,
  ): Promise<number> => {
    console.time(`findCellReferencingSheet - ${sheetName} : ${tableName}`);
    try {
      let index = -1;

      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItemOrNullObject(tableName);
        const tableRange = table.getRange();

        tableRange.load('formulas');
        table.columns.load('items');
        await context.sync();

        if (table.isNullObject) {
          console.log(`Table ${tableName} does not exist in sheet ${sheetName}.`);
          return;
        }

        const columnIndex = table.columns.items.findIndex(({ name }) => name === columnName);
        if (columnIndex === -1) {
          console.log(`Column ${columnName} does not exist in table ${tableName}`);
          return;
        }

        index = tableRange.formulas.findIndex((row) => {
          const formula = row[columnIndex] as string;
          const reference = extractReferencingSheetFromFormula(formula);
          return reference === referencedSheet;
        });
      });

      return index;
    } catch (error) {
      if (error instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in findCellReferencingSheet:', error.debugInfo);
      } else {
        logError(appInsights, 'Error in findCellReferencingSheet:', error);
      }
      throw error;
    } finally {
      console.timeEnd(`findCellReferencingSheet - ${sheetName} : ${tableName}`);
    }
  };

  const addRowOnTable = async (
    sheetName: string,
    tableName: string,
    rows: TableDataArray,
  ) => {
    console.time(`addRowOnTable - ${tableName}`);
    try {
      await Excel.run(async (context) => {
        // Get range and load values
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItemOrNullObject(tableName);
        const tableRange = table.getRange();

        tableRange.load('values');
        await context.sync();

        // If table does not exist, log and return
        if (table.isNullObject) {
          console.log(`Table ${tableName} does not exist in sheet ${sheetName}.`);
          return;
        }

        // Get the range below the table, insert a row, get a range that encompasses both ranges, and resize table
        const rangeBelowTable = tableRange.getRowsBelow();
        const tableDataValueRange = rangeBelowTable.insert(Excel.InsertShiftDirection.down);
        const newRange = tableRange.getBoundingRect(tableDataValueRange);
        table.resize(newRange);
        await context.sync();

        // Set values for the new row
        tableDataValueRange.values = rows;
        newRange.load('values');
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in addRowOnTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in addRowOnTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd(`addRowOnTable - ${tableName}`);
    }
  };

  const updateRowOnTable = async (sheetName: string, tableName: string, rowIndex: number, rows: TableDataArray) => {
    console.time(`updateRowOnTable - ${tableName}`);

    if (rowIndex === -1) {
      console.log('Row index cannot be -1');
      return;
    }

    try {
      await Excel.run(async (context) => {
        // Get table
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItemOrNullObject(tableName);
        await context.sync();

        // If table does not exist, log and return
        if (table.isNullObject) {
          console.log(`Table ${tableName} does not exist in sheet ${sheetName}.`);
          return;
        }

        // Get the row to update and load row values
        const tableData = table.getDataBodyRange();
        const row = tableData.getRow(rowIndex);

        row.load('values');
        await context.sync();

        // If row does not exist, log and return
        if (!row) {
          console.log(`Row ${rowIndex} does not exist in table ${tableName}`);
          return;
        }

        // Set values for the new row
        row.values = rows;
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in updateRowOnTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in updateRowOnTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd(`updateRowOnTable - ${tableName}`);
    }
  };

  const removeRowByReferencingSheet = async (
    sheetName: string,
    tableName: string,
    headerName: string,
    referencingSheet: string,
  ) => {
    let result;

    console.time('removeRowByReferencingSheet');
    try {
      await Excel.run(async (context) => {
        // Get the table and load the columns
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItemOrNullObject(tableName);

        table.columns.load('items');
        await context.sync();

        // If table does not exist, log and return
        if (table.isNullObject) {
          console.log(`Table ${tableName} does not exist in sheet ${sheetName}.`);
          result = false;
          return;
        }

        // Get the column for the header name
        const column = table.columns.items.find((item) => item.name === headerName);

        // If column does not exist, log and return
        if (!column) {
          console.log(`Column with header ${headerName} does not exist in the table ${tableName}.`);
          result = false;
          return;
        }

        // Get the range and load values
        const tableData = table.getDataBodyRange();
        tableData.load('formulas');
        await context.sync();

        // Iterate through rows to find the row with the value at the column index
        let range: Excel.Range | undefined;
        for (let i = 0; i < tableData.formulas.length; i += 1) {
          const formula = tableData.formulas[i][column.index] as string;
          const reference = extractReferencingSheetFromFormula(formula);
          if (reference === referencingSheet) {
            range = tableData.getRow(i);
            break;
          }
        }

        // If row does not exist, log and return
        if (!range) {
          // eslint-disable-next-line max-len
          console.log(`Row referencing sheet ${referencingSheet} not found in column ${headerName} in the table ${tableName}`);
          result = false;
          return;
        }

        // Delete the row
        range.delete(Excel.DeleteShiftDirection.up);
        await context.sync();

        result = true;
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in removeRowByReferencingSheet:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in removeRowByReferencingSheet:', err);
      }
      throw err;
    } finally {
      console.timeEnd('removeRowByValue');
    }

    return result;
  };

  const removeRowByValue = async ({
    sheetName, tableName, headerName, value,
  }: RemoveRowByValue) => {
    let result;

    console.time('removeRowByValue');
    try {
      await Excel.run(async (context) => {
        // Get the table and load the columns
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const table = sheet.tables.getItemOrNullObject(tableName);

        table.columns.load('items');
        await context.sync();

        // If table does not exist, log and return
        if (table.isNullObject) {
          console.log(`Table ${tableName} does not exist in sheet ${sheetName}.`);
          result = false;
          return;
        }

        // Get the column for the header name
        const column = table.columns.items.find((item) => item.name === headerName);

        // If column does not exist, log and return
        if (!column) {
          console.log(`Column with header ${headerName} does not exist in the table ${tableName}.`);
          result = false;
          return;
        }

        // Get the range and load values
        const tableData = table.getDataBodyRange();
        tableData.load('values');
        await context.sync();

        // Iterate through rows to find the row with the value at the column index
        let range: Excel.Range | undefined;
        for (let i = 0; i < tableData.values.length; i += 1) {
          if (tableData.values[i][column.index] === value) {
            range = tableData.getRow(i);
            break;
          }
        }

        // If row does not exist, log and return
        if (!range) {
          console.log(`Row with value ${value} not found in column ${headerName} in the table ${tableName}`);
          result = false;
          return;
        }

        // Delete the row
        range.delete(Excel.DeleteShiftDirection.up);
        await context.sync();

        result = true;
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in removeRowByValue:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in removeRowByValue:', err);
      }
      throw err;
    } finally {
      console.timeEnd('removeRowByValue');
    }

    return result;
  };

  /**
   * Insert data for multiple tables in the same sheet
   * @param multipleTablesData array of populate table data to populate tables
   */
  const populateMultipleTables = async (sheetName: string, multipleTablesData: PopulateTablesData[]) => {
    console.group('populateMultipleTables');
    console.time('populateMultipleTables');
    try {
      await Excel.run(async (context) => {
        if (multipleTablesData.length === 0) {
          return;
        }

        const syncedDataForPopulating: SyncedTableData[] = [];
        const sheet = context.workbook.worksheets.getItem(sheetName);
        const tableWithData = sheet?.tables?.load('name');
        await context.sync();
        const tableNames = tableWithData?.items?.map((item) => item.name);

        multipleTablesData.forEach((populateTableData) => {
          if (Object.keys(populateTableData).length === 0) {
            return;
          }
          const tableName = populateTableData.tableData?.name;
          if (!tableName || !tableNames.includes(tableName)) {
            console.log(`Table ${tableName} does not exist in sheet ${sheetName}. Skipping populate`);
            return;
          }

          if (!populateTableData.tableData?.data?.length) {
            console.log(`Provided data for ${tableName} table is empty. Skipping populate`);
            return;
          }

          const table = sheet.tables.getItemOrNullObject(tableName);
          const existingTableRange = table.getDataBodyRange();
          existingTableRange.load('values');

          syncedDataForPopulating.push({
            syncedTable: table,
            tableData: populateTableData.tableData.data,
            existingTableRange,
            isBatchInsert: !!populateTableData.batchInsert,
            isCleanInsert: populateTableData.cleanInsert,
          });
        });
        if (syncedDataForPopulating.length === 0) {
          return;
        }

        await context.sync();

        // suspending updates while all tables are populated
        context.application.suspendScreenUpdatingUntilNextSync();
        type RangeAndTableData = { range: Excel.Range; tableData: TableDataArray };
        const rangesAndTableData: RangeAndTableData[] = [];
        console.groupCollapsed('populateMultipleTables | table preparation');
        syncedDataForPopulating.forEach((data, index) => {
          const {
            syncedTable, tableData, existingTableRange, isBatchInsert, isCleanInsert,
          } = data;
          const tableNameLog = multipleTablesData[index].tableData?.name || `unknownTableName ${index}`;
          console.log(`populateTable | ${tableNameLog} | ${tableData.length} rows`);
          if (isCleanInsert) {
            console.time(`populateMultipleTables ${tableNameLog} - Clean Insert: Adjusting range size`);
            // Make sure range is the correct size
            const rowsInExistingRange = existingTableRange.values.length;
            const rowsInNewRange = tableData.length;
            const rowDiscrepancy = Math.abs(rowsInExistingRange - rowsInNewRange);

            if (rowDiscrepancy === 0) {
              rangesAndTableData.push({ range: existingTableRange, tableData });
              console.timeEnd(`populateMultipleTables ${tableNameLog} - Clean Insert: Adjusting range size`);
              return;
            }

            if (rowsInNewRange > rowsInExistingRange) {
              const lengthOfRow = tableData[0].length;
              const emptyRow = Array(lengthOfRow).fill('');
              const extraEmptyRows = Array(rowDiscrepancy).fill(emptyRow);
              if (isBatchInsert) {
                syncedTable.rows.add(0, extraEmptyRows, true);
              } else {
                extraEmptyRows.forEach((row) => {
                  syncedTable.rows.add(-1, [row], true);
                });
              }
              const newTableRange = syncedTable.getDataBodyRange();
              newTableRange.load('values');
              rangesAndTableData.push({ range: newTableRange, tableData });
              console.timeEnd(`populateMultipleTables ${tableNameLog} - Clean Insert: Adjusting range size`);
              return;
            }

            if (rowsInExistingRange > rowsInNewRange) {
              const rowsToDelete = [];
              for (let i = rowsInNewRange; i < rowsInExistingRange; i += 1) {
                rowsToDelete.push(i);
              }
              syncedTable.rows.deleteRows(rowsToDelete);
              const newTableRange = syncedTable.getDataBodyRange();
              newTableRange.load('values');
              rangesAndTableData.push({ range: newTableRange, tableData });
            }
            console.timeEnd(`populateMultipleTables ${tableNameLog} - Clean Insert: Adjusting range size`);
            // If not clean insert add rows
          } else if (isBatchInsert) {
            console.time(`populateMultipleTables ${tableNameLog} - Batch Insert`);
            syncedTable.rows.add(-1, tableData, true);
            console.timeEnd(`populateMultipleTables ${tableNameLog} - Batch Insert`);
          } else {
            console.time(`populateMultipleTables ${tableNameLog} - Row by Row`);
            tableData.forEach((tableRow) => {
              syncedTable.rows.add(-1, [tableRow], true);
            });
            console.timeEnd(`populateMultipleTables ${tableNameLog} - Row by Row`);
          }
        });

        await context.sync();

        console.time('populateMultipleTables | table data via range');
        // If clean insert replace values in correctly sized ranges
        rangesAndTableData.forEach((item) => {
          const { range, tableData } = item;
          range.values = tableData;
        });
        console.timeEnd('populateMultipleTables | table data via range');

        console.time('autofit Rows');
        sheet.getUsedRange().format.autofitRows();
        console.timeEnd('autofit Rows');

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in populateMultipleTables:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in populateMultipleTables:', err);
      }
      throw err;
    } finally {
      console.timeEnd('populateMultipleTables');
      console.groupEnd();
    }
  };

  const updateTableName = async ({ sheetName, currTableName, updatedTableName }: UpdateTableName) => {
    console.time('updateTableName');
    try {
      await Excel.run(async (context) => {
        const excelSheet = context.workbook.worksheets.getItem(sheetName);
        const table = excelSheet.tables.getItem(currTableName);

        table.name = updatedTableName;

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in updateTableName:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in updateTableName:', err);
      }
      throw err;
    } finally {
      console.timeEnd('updateTableName');
    }
  };

  const renameSheet = async ({ currentSheetName, newSheetName } : { currentSheetName:string, newSheetName:string }) => {
    console.time('renameSheet');
    await Excel.run(async (context) => {
      const conflictingSheet = context.workbook.worksheets.getItemOrNullObject(newSheetName);
      await context.sync();

      if (conflictingSheet.isNullObject) {
        const currentSheet = context.workbook.worksheets.getItem(currentSheetName);
        currentSheet.name = newSheetName;
        await context.sync();
      } else {
        console.timeEnd('renameSheet');
        throw new Error(`Sheet with name ${newSheetName} already exists`);
      }
      console.timeEnd('renameSheet');
    });
  };

  const deleteSheet = async ({ sheetName }: DeleteSheet) => {
    console.time('deleteSheet');
    try {
      await Excel.run(async (context) => {
        const excelSheet = context.workbook.worksheets.getItem(sheetName);
        excelSheet.delete();
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in deleteSheet:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in deleteSheet:', err);
      }
      throw err;
    } finally {
      console.timeEnd('deleteSheet');
    }
  };

  const protectSheet = async ({ sheetName }: ProtectSheet) => {
    console.time('protectSheet');
    try {
      await Excel.run(async (context) => {
        const excelSheet = context.workbook.worksheets.getItem(sheetName);
        excelSheet.protection.protect({});
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in protectSheet:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in protectSheet:', err);
      }
      throw err;
    } finally {
      console.timeEnd('protectSheet');
    }
  };

  const applyValuesToNamedRanges = async ({ sheet, namedRangeAssignments }: ApplyNamedRangeAssignments) => {
    console.time('applyValuesToNamedRanges');
    try {
      await Excel.run(async (context) => {
        const excelSheet = context.workbook.worksheets.getItem(sheet);
        const excelWorkbook = context.workbook;
        const namedRangesInSheet = excelSheet.names;
        const namedRangesInWorkbook = excelWorkbook.names;
        namedRangesInSheet.load('items');
        namedRangesInWorkbook.load('items');
        await context.sync();

        // Helper for us to verify named range is in sheet
        const namedRangesInSheetMap: string[] = namedRangesInSheet.items.map((item) => item.name);
        const namedRangesInWorkbookMap: string[] = namedRangesInWorkbook.items.map((item) => item.name);

        namedRangeAssignments.forEach((assignment) => {
          if (namedRangesInSheetMap.indexOf(assignment.range) < 0
            && namedRangesInWorkbookMap.indexOf(assignment.range) < 0
          ) {
            console.warn(`The range was not in the sheet: ${assignment.range}`);
          } else if (assignment.value !== null && assignment.value !== undefined) {
            const range = excelSheet.getRange(assignment.range);
            range.values = [[assignment.value]];
          }
        });

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in applyValuesToNamedRanges:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in applyValuesToNamedRanges:', err);
      }
      throw err;
    } finally {
      console.timeEnd('applyValuesToNamedRanges');
    }
  };

  const applyFormatsToNamedRanges = async ({ sheet, namedRangeAssignments }: ApplyNamedRangeAssignments) => {
    console.time('applyFormatsToNamedRanges');
    try {
      await Excel.run(async (context) => {
        const excelSheet = context.workbook.worksheets.getItem(sheet);
        const excelWorkbook = context.workbook;
        const namedRangesInSheet = excelSheet.names;
        const namedRangesInWorkbook = excelWorkbook.names;
        namedRangesInSheet.load('items');
        namedRangesInWorkbook.load('items');
        await context.sync();

        // Helper for us to verify named range is in sheet
        const namedRangesInSheetMap: string[] = namedRangesInSheet.items.map((item) => item.name);
        const namedRangesInWorkbookMap: string[] = namedRangesInWorkbook.items.map((item) => item.name);

        const rangeAreasArray = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const assignment of namedRangeAssignments) {
          if (namedRangesInSheetMap.indexOf(assignment.range) < 0
            && namedRangesInWorkbookMap.indexOf(assignment.range) < 0
          ) {
            console.warn(`The range was not in the sheet: ${assignment.range}`);
          } else {
            const rangeArea = excelSheet.getRanges(assignment.range);
            const rangeAreas = rangeArea.areas;
            rangeAreas.load('items');
            rangeAreasArray.push({ rangeAreas, format: assignment.format });
          }
        }

        await context.sync();

        rangeAreasArray.forEach((ra) => {
          const { items } = ra.rangeAreas;

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < items.length; i++) {
            const range = ra.rangeAreas.getItemAt(i);

            if (ra.format !== null && ra.format !== undefined) {
              range.numberFormat = [[ra.format]];
            }
          }
        });

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in applyFormatsToNamedRanges:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in applyFormatsToNamedRanges:', err);
      }
      throw err;
    } finally {
      console.timeEnd('applyFormatsToNamedRanges');
    }
  };

  const getValuesFromNamedRanges = async ({ sheet, namedRanges }: GetValuesFromNamedRanges) => {
    const returnMap: Record<string, string | number | undefined> = {};

    console.time('getValuesFromNamedRanges');
    try {
      await Excel.run(async (context) => {
        const excelRanges: NamedRangeObj[] = [];
        const excelSheet = context.workbook.worksheets.getItem(sheet);
        const excelWorkbook = context.workbook;
        const namedRangesInSheet = excelSheet.names;
        const namedRangesInWorkbook = excelWorkbook.names;
        namedRangesInSheet.load('items');
        namedRangesInWorkbook.load('items');
        await context.sync();

        // Helper for us to verify named range is in sheet
        const namedRangesInSheetMap: string[] = namedRangesInSheet.items.map((item) => item.name);
        const namedRangesInWorkbookMap: string[] = namedRangesInWorkbook.items.map((item) => item.name);

        namedRanges.forEach((rangeName) => {
          if (namedRangesInSheetMap.indexOf(rangeName) < 0 && namedRangesInWorkbookMap.indexOf(rangeName) < 0) {
            console.warn(`The range was not in the sheet: ${rangeName}`);
            setNotification({
              type: SnackbarType.WARNING,
              text: `Unable to find Named Range: ${rangeName} in Worksheet: ${sheet}`,
              duration: 6000,
            });
            excelRanges.push({ name: rangeName, range: undefined });
          } else {
            const range = excelSheet.getRange(rangeName);
            range.load('values');
            excelRanges.push({ name: rangeName, range });
          }
        });

        await context.sync();

        excelRanges.forEach((syncedRange) => {
          let value;
          if (syncedRange.range && syncedRange.range.values?.length && syncedRange.range.values[0].length) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const checkVal = syncedRange.range.values[0][0]; // namedRange.values are of type any
            if (checkVal === undefined || String(checkVal).trim().length === 0) {
              value = undefined;
            } else {
              value = Number.isNaN(Number(checkVal)) ? String(checkVal) : Number(checkVal);
            }
          }
          returnMap[syncedRange.name] = value;
        });
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in getValuesFromNamedRanges:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in getValuesFromNamedRanges:', err);
      }
      throw err;
    } finally {
      console.timeEnd('getValuesFromNamedRanges');
    }

    return returnMap;
  };

  const addSheetNamePrefixToTables = async ({ sheetName }: { sheetName: string }) => {
    console.time('addSheetNamePrefixToTables');

    try {
      await Excel.run(async (context: Excel.RequestContext) => {
        // Update the table names
        const sheet = context.workbook.worksheets.getItem(sheetName);
        await context.sync();

        const tables = sheet.tables.load('name');
        await context.sync();

        const tablePrefix = sheetName.replace(/[^a-zA-Z0-9]+/g, '');
        for (let i = 0; i < tables.items.length; i += 1) {
          const currName = tables.items[i].name;

          const newName = currName.slice(currName.indexOf('_'));
          tables.items[i].name = `${tablePrefix}${newName}`;
        }
      });
    } catch (error) {
      if (error instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in addSheetNamePrefixToTables:', error.debugInfo);
      } else {
        logError(appInsights, 'Error in addSheetNamePrefixToTables:', error);
      }
      throw error;
    } finally {
      console.timeEnd('addSheetNamePrefixToTables');
    }
  };

  const copySheet = async ({ sheetToCopy, newSheetName, customProperties }: CopySheet) => {
    console.time('copySheet');
    try {
      await Excel.run(async (context) => {
        const template = context.workbook.worksheets.getItem(sheetToCopy);
        // Copy sheet to provided new sheet name
        const copiedSheet = template.copy(Excel.WorksheetPositionType.after, template);
        await context.sync();

        // Update the table names
        const copiedTables = copiedSheet.tables;

        copiedTables.load('name');
        await context.sync();

        const tablePrefix = newSheetName.replace(/[^a-zA-Z0-9]+/g, '');
        for (let i = 0; i < copiedTables.items.length; i += 1) {
          const currName = copiedTables.items[i].name;
          const newName = currName.slice(0, currName.lastIndexOf('_'));
          copiedTables.items[i].name = `${tablePrefix}_${newName}`;
        }

        // Update the properties for the sheet
        customProperties.forEach((p) => copiedSheet.customProperties.add(p.property, p.value));
        copiedSheet.name = newSheetName;
        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in copySheet:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in copySheet:', err);
      }
      throw err;
    } finally {
      console.timeEnd('copySheet');
    }
  };

  const getCustomProperties = async (sheet?: string) => {
    try {
      return await Excel.run(async (context) => {
        const returnMap: Record<string, string | number | undefined> = {};
        if (sheet) {
          const customSheetProps = context.workbook.worksheets.getItem(sheet).customProperties;
          customSheetProps.load(['key', 'value']);
          await context.sync();

          return customSheetProps.items.reduce(
            (map, prop) => Object.assign(map, { [prop.key]: prop.value }),
            returnMap,
          );
        }

        const customDocProperties = context.workbook.properties.custom;

        customDocProperties.load(['key', 'value']);
        await context.sync();

        return customDocProperties.items.reduce(
          (map, prop) => Object.assign(map, { [prop.key]: prop.value as string }),
          returnMap,
        );
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in getCustomProperties:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in getCustomProperties:', err);
      }
      throw err;
    }
  };

  const setCustomProperty = async (key: string, value: string, sheet?: string) => {
    console.time('setCustomProperty');
    try {
      await Excel.run(async (context) => {
        if (sheet) {
          const customSheetProps = context.workbook.worksheets.getItem(sheet).customProperties;
          customSheetProps.add(key, value);

          await context.sync();
          return;
        }

        const customDocProperties = context.workbook.properties.custom;
        customDocProperties.add(key, value);

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in setCustomProperty:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in setCustomProperty:', err);
      }
      throw err;
    } finally {
      console.timeEnd('setCustomProperty');
    }
  };

  const removeWorkbookCustomProperty = async (key: string) => {
    console.time('removeWorkbookCustomProperty');
    try {
      await Excel.run(async (context) => {
        const customWorkbookProperties = context.workbook.properties.custom;
        const prop = customWorkbookProperties.getItemOrNullObject(key);

        await context.sync();

        if (prop) {
          prop.delete();
          await context.sync();
        }
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in removeWorkbookCustomProperty:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in removeWorkbookCustomProperty:', err);
      }
      throw err;
    } finally {
      console.timeEnd('removeWorkbookCustomProperty');
    }
  };

  const loadExcelFileUrlPromise: () => Promise<string> = async () =>
    new Promise((resolve) => {
      Office.context.document.getFilePropertiesAsync({ asyncContext: null }, (response) => {
        resolve(response.value.url);
      });
    });

  const loadExcelFileUrl = async () => {
    let fileUrl;

    console.time('loadExcelFileUrl');
    try {
      fileUrl = await loadExcelFileUrlPromise();
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in loadExcelFileUrl:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in loadExcelFileUrl:', err);
      }
      throw err;
    } finally {
      console.timeEnd('loadExcelFileUrl');
    }
    return fileUrl;
  };

  const loadExcelParentDirectoryPromise: () => Promise<string> = async () =>
    new Promise((resolve) => {
      Office.context.document.getFilePropertiesAsync({ asyncContext: null }, (response) => {
        const parentDirectory = response.value.url.split('/').slice(0, -1).join('/');
        resolve(parentDirectory);
      });
    });

  const loadExcelParentDirectory = async () => {
    let parentDirectory;

    console.time('loadExcelParentDirectory');
    try {
      parentDirectory = await loadExcelParentDirectoryPromise();
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in loadExcelParentDirectory:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in loadExcelParentDirectory:', err);
      }
      throw err;
    } finally {
      console.timeEnd('loadExcelParentDirectory');
    }

    return parentDirectory;
  };

  const saveWorkbookOriginalFileLocation = async (parentDir: string) => {
    console.time('saveWorkbookOriginalFileLocation');
    const parentDirFullPathArray = parentDir.split('/');
    const parentDirTourFolder = parentDirFullPathArray.pop() as string;
    const parentDirPath = parentDirFullPathArray.join('/');
    if (parentDirTourFolder && parentDirTourFolder.length > 0 && parentDirPath && parentDirPath.length > 0) {
      await setCustomProperty(WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER, parentDirTourFolder);
      await setCustomProperty(WORKBOOK_ORIGINAL_PARENT_DIR_PATH, parentDirPath);
      await removeWorkbookCustomProperty(WORKBOOK_ORIGINAL_PARENT_DIR);
    }
    console.timeEnd('saveWorkbookOriginalFileLocation');
  };

  /**
   * The general pattern to use this function is to call it
   * wherever we want to check if the workbook location has changed.
   * If the location has changed, we throw an error that reroutes the
   * Add-In to an error state.
   */
  const checkIfWorkbookIsSyncedToSharepoint = async () => {
    console.time('checkIfWorkbookIsSyncedToSharepoint');
    const parentDir = await loadExcelParentDirectory();
    const workbookProperties = await getCustomProperties();
    const originalParentDir = workbookProperties[WORKBOOK_ORIGINAL_PARENT_DIR] as string;
    const originalParentDirTourFolder = workbookProperties[WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER] as string;
    const originalParentDirPath = workbookProperties[WORKBOOK_ORIGINAL_PARENT_DIR_PATH] as string;

    // Use new values from custom properties if they exist
    if (originalParentDirTourFolder && originalParentDirPath) {
      if (parentDir !== `${originalParentDirPath}/${originalParentDirTourFolder}`) {
        navigateOnError({
          message: WORKBOOK_ORIGINAL_FILE_DEVIATION_ERROR_MSG,
          type: NavigateErrorType.NotSynced,
        });
      }
      // Use deprecated property which has a truncated value in excel 16.85 on mac
    } else if (!parentDir.startsWith(originalParentDir)) {
      navigateOnError({
        message: WORKBOOK_ORIGINAL_FILE_DEVIATION_ERROR_MSG,
        type: NavigateErrorType.NotSynced,
      });
      // TODO TECH DEBT: instead of navigating, find a way to catch a thrown error
      // from the context.workbook.onActivated event listener.
      // Ticket: https://aeglive.atlassian.net/browse/SANE-6408
    } else {
      await saveWorkbookOriginalFileLocation(parentDir);
    }
    console.timeEnd('checkIfWorkbookIsSyncedToSharepoint');
  };

  /**
   * This function calls the workbook change detection function
   * checkIfWorkbookIsSyncedToSharepoint() whenever Excel is re-focused
   * from another application or another workbook. Activation here means
   * focusing on the current workbook.
   */
  const addActivationListenerToCheckForSharepoint = async () => {
    console.time('addActivationListenerToCheckForSharepoint');
    try {
      await Excel.run(async (context) => {
        await context.sync();
        context.workbook.onActivated.add(async () => {
          await checkIfWorkbookIsSyncedToSharepoint();
        });
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in addActivationListenerToCheckForSharepoint:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in addActivationListenerToCheckForSharepoint:', err);
      }
      throw err;
    } finally {
      console.timeEnd('addActivationListenerToCheckForSharepoint');
    }
  };

  /**
   * Removes sheet rename listener.
   */
  const removeSheetRenameListenerToWorkbook = async () => {
    if (!sheetRenameListener) { return; }
    console.time('removeSheetRenameListenerToWorkbook');
    try {
      await Excel.run(sheetRenameListener.context, async (context) => {
        if (!sheetRenameListener) { return; }
        sheetRenameListener.remove();
        await context.sync();
        sheetRenameListener = undefined;
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in removeSheetRenameListenerToWorkbook:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in removeSheetRenameListenerToWorkbook:', err);
      }
      throw err;
    } finally {
      console.timeEnd('removeSheetRenameListenerToWorkbook');
    }
  };

  const renameWorkbookListenerCallback = (
    cb: (args: Excel.WorksheetNameChangedEventArgs) => any,
  ) => async (args: Excel.WorksheetNameChangedEventArgs) => {
    await checkIfWorkbookIsSyncedToSharepoint();
    await cb(args);
  };

  /**
   * This function accepts a callback to call whenever a sheet is renamed.
   */
  const addSheetRenameListenerToWorkbook = async (cb: (
    args: Excel.WorksheetNameChangedEventArgs,
  ) => any) => {
    console.time('addSheetRenameListenerToWorkbook');
    try {
      await Excel.run(async (context) => {
        await context.sync();
        sheetRenameListener = context.workbook.worksheets.onNameChanged.add(renameWorkbookListenerCallback(cb));
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in addSheetRenameListenerToWorkbook:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in addSheetRenameListenerToWorkbook:', err);
      }
      throw err;
    } finally {
      console.timeEnd('addSheetRenameListenerToWorkbook');
    }
  };

  const parseTable = async <T extends Record<string, unknown>>(
    sheetName: string,
    tableName: string,
    defaultObj?: T,
    includeTotals?: boolean,
  ): Promise<Array<T>> => {
    const objectArray = new Array<T>();

    console.time('parseTable');
    try {
      await Excel.run(async (context) => {
        const table = context.workbook.worksheets.getItem(sheetName).tables.getItem(tableName);

        if (!table) {
          setNotification({
            type: SnackbarType.WARNING,
            text: `Table ${tableName} does not exist in sheet ${sheetName}.`,
            duration: 6000,
          });
        }

        await context.sync();

        // Loop through columns to find header
        //  We can't use tableHeaderRange, as the header may be hidden
        table.columns.load('items');
        await context.sync();
        const tableHeaders = table.columns.items.map((item) => item.name);

        // load body of the table
        const bodyRange = table.getDataBodyRange();
        bodyRange.load('values');
        await context.sync();
        const dataValues = bodyRange.values;

        // load table subtotals if requested
        if (includeTotals) {
          const totalRange = table.getTotalRowRange();
          totalRange.load('values');
          await context.sync();
          dataValues.push(totalRange.values[0]);
        }

        // For each row, create a JSON object and assign each property to it based on the table headers.

        for (let i = 0; i < dataValues.length; i += 1) {
          // Create a blank generic JSON object.
          const object: Record<string, unknown> = {};
          let isEmpty = true;
          for (let j = 0; j < dataValues[i].length; j += 1) {
            // If a default object shape is not passed, add the property to object if it's not empty
            // If a default object shape is passed, convert to proper type of Number or String
            if (!defaultObj) {
              const trimmedValue = (String(dataValues[i][j])).trim();
              isEmpty = isEmpty && trimmedValue.length === 0;
              object[tableHeaders[j]] = trimmedValue.length === 0 ? undefined : dataValues[i][j];
            } else if (defaultObj && (defaultObj && tableHeaders[j] in defaultObj)) {
              const trimmedValue = (String(dataValues[i][j])).trim();
              isEmpty = isEmpty && trimmedValue.length === 0;
              if (trimmedValue.length === 0) {
                object[tableHeaders[j]] = undefined;
              } else if (typeof defaultObj[tableHeaders[j]] === 'number') {
                object[tableHeaders[j]] = Number(dataValues[i][j]);
              } else if (typeof defaultObj[tableHeaders[j]] === 'string') {
                object[tableHeaders[j]] = trimmedValue;
              }
            }
          }
          if (!isEmpty) {
            objectArray.push(object as T);
          }
        }
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in parseTable:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in parseTable:', err);
      }
      throw err;
    } finally {
      console.timeEnd('parseTable');
    }

    return objectArray;
  };

  const currentWorkbookSheetNames = async (): Promise<string[]> => {
    let sheetNames: string[] = [];

    console.time('currentWorkbookSheetNames');
    try {
      await Excel.run(async (context) => {
        const sheets = context.workbook.worksheets;
        sheets.load('items/name');
        await context.sync();
        sheetNames = sheets.items.map((sheet) => sheet.name);
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in currentWorkbookSheetNames:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in currentWorkbookSheetNames:', err);
      }
      throw err;
    } finally {
      console.timeEnd('currentWorkbookSheetNames');
    }

    return sheetNames;
  };

  /**
   * This function throws if named range does not exist.
   * To retrieve a named range without throwing and failing gracefully use getValuesFromNamedRanges.
   */
  const getValueByRangeName = async <T extends string | number>(
    rangeName: string,
    sheetName: string,
  ): Promise<T | undefined> => {
    let value;

    console.time('getValueByRangeName');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);
        let range: Excel.Range | undefined;

        range = sheet.getRange(rangeName);
        range = range.load();
        await context.sync();

        if (!range?.values?.length || !range.values[0].length) {
          console.log(`No cell value for range name ${rangeName} found in sheet ${sheetName}`);
          value = undefined;
          return;
        }

        value = range.values[0][0] as T;
        if (value === '') {
          console.log(`No cell value for range name ${rangeName} found in sheet ${sheetName}`);
          value = undefined;
        }
      });
    } catch (err) {
      setNotification({
        type: SnackbarType.WARNING,
        text: `Unable to find Named Range: ${rangeName} in Worksheet: ${sheetName}`,
        duration: 6000,
      });
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in getValueByRangeName:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in getValueByRangeName:', err);
      }
      throw err;
    } finally {
      console.timeEnd('getValueByRangeName');
    }

    return value;
  };

  const setSheetVisibility = async (sheetName: string, isVisible: boolean) => {
    console.time('setSheetVisibility');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);

        sheet.visibility = isVisible ? Excel.SheetVisibility.visible : Excel.SheetVisibility.hidden;

        await context.sync();
      });
    } catch (err) {
      if (err instanceof OfficeExtension.Error) {
        logError(appInsights, 'Excel Error in setSheetVisibility:', err.debugInfo);
      } else {
        logError(appInsights, 'Error in setSheetVisibility:', err);
      }
      throw err;
    } finally {
      console.timeEnd('setSheetVisibility');
    }
  };

  /**
   * Checks workbook for existence of named range to assist with backwards compatibility
  */
  const checkWorkbookForNamedRange = async (rangeName: string): Promise<boolean> => {
    console.time('checkWorkbookForNamedRange');
    try {
      await Excel.run(async (context) => {
        // Attempt to get the named range
        const namedRange = context.workbook.names.getItem(rangeName);
        // Load the name property to ensure it exists
        namedRange.load('name');
        await context.sync();
      });
      // If no error is thrown, the named range exists
      return true;
    } catch (error) {
      // Check the error code to see if it's because the named range doesn't exist
      if (error.code === 'ItemNotFound') {
        // Named range does not exist
        return false;
      }
      // Re-throw the error if it's due to another reason
      throw error;
    } finally {
      console.timeEnd('checkWorkbookForNamedRange');
    }
  };

  /**
   * Checks worksheet for existence of named range to assist with backwards compatibility
  */
  const checkWorksheetForNamedRange = async (sheetName: string, rangeName: string): Promise<boolean> => {
    console.time('checkWorksheetForNamedRange');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(sheetName);

        let range: Excel.Range | undefined;
        range = sheet.getRange(rangeName);
        range = range.load();
        await context.sync();
      });
      // If no error is thrown, the named range exists
      return true;
    } catch (error) {
      // Check the error code to see if it's because the named range doesn't exist
      if (error.code === 'ItemNotFound') {
        // Named range does not exist
        return false;
      }
      // Re-throw the error if it's due to another reason
      throw error;
    } finally {
      console.timeEnd('checkWorksheetForNamedRange');
    }
  };

  const activateWorksheet = async (worksheetName: string) => {
    console.time('activateWorksheet');
    try {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getItem(worksheetName);
        sheet.activate();

        await context.sync();
      });
    } catch (error) {
      logError(appInsights, 'Error in activateWorksheet:', error);
    } finally {
      console.timeEnd('activateWorksheet');
    }
  };

  if (isAutomationEnabled) {
    return {
      saveWorkbook: () => Promise.resolve(),
      sortTable: () => Promise.resolve(),
      tableHasFilters: () => Promise.resolve(false),
      mapFilterCriteriaToFilterData: () => [],
      addFilters: () => {},
      addFilterByValuesToTable: () => Promise.resolve(),
      reapplyFiltersToTable: () => Promise.resolve(),
      removeFiltersOnTable: () => Promise.resolve(),
      setTabColor: () => Promise.resolve(),
      getTableHeaders: () => Promise.resolve([]),
      reorderWorksheets: () => Promise.resolve(undefined),
      renameSheet: () => Promise.resolve(false),
      applyValuesToNamedRanges: () => Promise.resolve(false),
      applyFormatsToNamedRanges: () => Promise.resolve(false),
      loadExcelFileUrl: () => Promise.resolve(''),
      loadExcelParentDirectory: () => Promise.resolve(''),
      checkIfWorkbookIsSyncedToSharepoint: () => Promise.resolve(undefined),
      checkWorkbookForNamedRange: () => Promise.resolve(true),
      checkWorksheetForNamedRange: () => Promise.resolve(true),
      saveWorkbookOriginalFileLocation: () => Promise.resolve(undefined),
      addActivationListenerToCheckForSharepoint: () => Promise.resolve(undefined),
      addSheetRenameListenerToWorkbook: () => Promise.resolve(undefined),
      removeSheetRenameListenerToWorkbook: () => Promise.resolve(),
      copySheet: () => Promise.resolve(false),
      currentWorkbookSheetNames: () => Promise.resolve([]),
      getCustomProperties: () => Promise.resolve({} as Record<string, string>),
      deleteSheet: () => Promise.resolve(false),
      getValueByRangeName: () => Promise.resolve(undefined),
      parseTable: () => Promise.resolve([]),
      findCellReferencingSheet: () => Promise.resolve(false),
      addRowOnTable: () => Promise.resolve(),
      updateRowOnTable: () => Promise.resolve(),
      populateMultipleTables: () => Promise.resolve(),
      protectSheet: () => Promise.resolve(false),
      removeRowByReferencingSheet: () => Promise.resolve(),
      removeRowByValue: () => Promise.resolve(true),
      setCalculationMode: () => Promise.resolve(),
      setCustomProperty: () => Promise.resolve(false),
      removeWorkbookCustomProperty: () => Promise.resolve(),
      updateTableName: () => Promise.resolve(),
      addSheetNamePrefixToTables: () => Promise.resolve(),
      getValuesFromNamedRanges: () => Promise.resolve({} as Record<string, string>),
      setSheetVisibility: () => Promise.resolve(),
      activateWorksheet: () => Promise.resolve(),
      renameWorkbookListenerCallback: () => () => Promise.resolve(),
    };
  }

  return {
    saveWorkbook,
    sortTable,
    tableHasFilters,
    mapFilterCriteriaToFilterData,
    addFilters,
    addFilterByValuesToTable,
    reapplyFiltersToTable,
    removeFiltersOnTable,
    setTabColor,
    getTableHeaders,
    reorderWorksheets,
    renameSheet,
    applyValuesToNamedRanges,
    applyFormatsToNamedRanges,
    loadExcelFileUrl,
    loadExcelParentDirectory,
    checkIfWorkbookIsSyncedToSharepoint,
    checkWorkbookForNamedRange,
    checkWorksheetForNamedRange,
    saveWorkbookOriginalFileLocation,
    addActivationListenerToCheckForSharepoint,
    addSheetRenameListenerToWorkbook,
    removeSheetRenameListenerToWorkbook,
    copySheet,
    currentWorkbookSheetNames,
    deleteSheet,
    getCustomProperties,
    getValueByRangeName,
    parseTable,
    findCellReferencingSheet,
    addRowOnTable,
    updateRowOnTable,
    populateMultipleTables,
    protectSheet,
    removeRowByReferencingSheet,
    removeRowByValue,
    setCalculationMode,
    setCustomProperty,
    removeWorkbookCustomProperty,
    updateTableName,
    addSheetNamePrefixToTables,
    getValuesFromNamedRanges,
    setSheetVisibility,
    activateWorksheet,
    // for unit testing only
    renameWorkbookListenerCallback,
  };
}
