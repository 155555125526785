import { useEffect } from 'react';

export function useCallbackOnWindowFocus(callback: () => Promise<void>) {
  const handleChange = () => {
    void callback();
  };

  useEffect(() => {
    window.addEventListener('focus', handleChange);
    return () => {
      window.removeEventListener('focus', handleChange);
    };
  }, []);
}
