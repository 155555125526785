import {
  GridColDef,
  GridRenderCellParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';

import {
  VenueNameCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  ScenarioBuilderCell,
  EventOfferActionsCell,
} from '@components';
import {
  ScenarioBuilder,
  ScenarioBuilderEventOffer,
  ScenarioBuilderProjection,
} from '@types';

export type ScenarioBuilderRow = (
  ScenarioBuilder &
  { isTotalsRow?: boolean; } &
  ScenarioBuilderEventOffer &
  ScenarioBuilderProjection
);
type ScenarioBuilderColumns = GridColDef<ScenarioBuilderRow>[];
type RenderCellParams = GridRenderCellParams<ScenarioBuilderRow>;

const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 178,
  maxWidth: 248,
};

const scenario1Column = {
  field: 'scenario1',
  headerName: 'Scenario 1',
  flex: 0.2,
  minWidth: 266,
  renderCell: ({ row }: RenderCellParams) => (
      <ScenarioBuilderCell
        name={row.name}
        ticketSales={row.scenarios?.[0]?.ticketSales}
        sellableCapacity={row.scenarios?.[0]?.sellableCapacity}
        netGrossPublic={row.scenarios?.[0]?.netGrossPublic}
        totalArtistEarnings={row.scenarios?.[0]?.totalArtistEarnings}
        clubTourPL={row.scenarios?.[0]?.clubTourPL}
        amount={
          row.summary?.scenarios?.[0]?.amount ?? row.scenarios?.[0]?.amount
        }
        type={row.summary?.scenarios?.[0]?.type ?? row.scenarios?.[0]?.type}
      />
  ),
};

const scenario2Column = {
  field: 'scenario2',
  headerName: 'Scenario 2',
  flex: 0.2,
  minWidth: 266,
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.scenarios?.[1]?.ticketSales}
      sellableCapacity={row.scenarios?.[1]?.sellableCapacity}
      netGrossPublic={row.scenarios?.[1]?.netGrossPublic}
      totalArtistEarnings={row.scenarios?.[1]?.totalArtistEarnings}
      clubTourPL={row.scenarios?.[1]?.clubTourPL}
      amount={row.summary?.scenarios?.[1]?.amount ?? row.scenarios?.[1]?.amount}
      type={row.summary?.scenarios?.[1]?.type ?? row.scenarios?.[1]?.type}
    />
  ),
};

const averageBreakevenColumn = {
  field: 'averageBreakeven',
  headerName: 'Average Breakeven',
  flex: 0.2,
  minWidth: 266,
  cellClassName: 'disabled-cell',
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.averageBreakeven?.ticketSales}
      sellableCapacity={row.averageBreakeven?.sellableCapacity}
      netGrossPublic={row.averageBreakeven?.netGrossPublic}
      totalArtistEarnings={row.averageBreakeven?.totalArtistEarnings}
      clubTourPL={row.averageBreakeven?.clubTourPL}
      amount={
        row.summary?.averageBreakeven?.amount ?? row.averageBreakeven?.amount
      }
      type={row.summary?.averageBreakeven?.type ?? row.averageBreakeven?.type}
      disabled
    />
  ),
};

const sequentialBreakevenColumn = {
  field: 'sequentialBreakeven',
  headerName: 'Sequential Breakeven',
  flex: 0.2,
  minWidth: 266,
  cellClassName: 'disabled-cell',
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.sequentialBreakeven?.ticketSales}
      sellableCapacity={row.sequentialBreakeven?.sellableCapacity}
      netGrossPublic={row.sequentialBreakeven?.netGrossPublic}
      totalArtistEarnings={row.sequentialBreakeven?.totalArtistEarnings}
      clubTourPL={row.sequentialBreakeven?.clubTourPL}
      amount={
        row.summary?.sequentialBreakeven?.amount ?? row.sequentialBreakeven?.amount
      }
      type={row.summary?.sequentialBreakeven?.type ?? row.sequentialBreakeven?.type}
      disabled
    />
  ),
};

export const scenarioBuilderColumns: ScenarioBuilderColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => !params.row.isTotalsRow && <ExpandButtonCell {...params} />,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell fontWeight={row.isTotalsRow ? '500' : 'normal'}>{row.name}</TextWrapPrettyCell>
      </LeftPinnedCell>
    ),
  },
  scenario1Column,
  scenario2Column,
  averageBreakevenColumn,
  sequentialBreakevenColumn,
];

export const scenarioBuilderEventOfferColumns: ScenarioBuilderColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          eventId={row.eventId}
          offerId={row.offerId}
          settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  scenario1Column,
  scenario2Column,
  averageBreakevenColumn,
  sequentialBreakevenColumn,
];
