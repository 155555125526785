enum FeatureFlags {
  BUNDLES = 'BUNDLES',
  CAN_UPDATE_VENUE = 'CAN_UPDATE_VENUE',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  NOS_SETTLEMENT_EMAIL = 'NOS_SETTLEMENT_EMAIL',
  NOS_SETTLEMENT_VERSIONING = 'NOS_SETTLEMENT_VERSIONING',
  ONE_OFFS_EVENTS_LIST = 'ONE_OFFS_EVENTS_LIST',
}

export default FeatureFlags;
