import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { Venue } from '@gql/types/graphql';
import { GET_VENUES } from '@gql/queries/venues';
import { venueRegionDisplayFieldGetter } from '@utils/venueHelpers';
import { SearchMarkets } from '../../SearchMarkets/SearchMarkets';
import { ListView, SearchBar } from '../../shared';
import { getVenueGridColumns, processVenue, searchVenueOptions } from './constants';
import {
  DisabledText,
  PlainTextButton,
  RadioOptionContainer,
  VenueSearchOptionsHeader,
  ModalContainer,
  RadioStyled,
} from './VenueSearch.styled';
import { VenueSearchOptions, VenueSearchProps } from './VenueSearch.types';

// eslint-disable-next-line max-lines-per-function
export function VenueSearch({
  onVenueSelect,
  venueSelectLabel,
  onVenueRowClick = () => {},
  showGridInfo = false,
  disableMarketSearch = false,
}: VenueSearchProps) {
  const [selectedSearchRadio, setSelectedSearchRadio] = useState('name');
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);

  const selectedGridData = useMemo(() => processVenue(selectedVenue), [selectedVenue]);

  const searchProps = {
    query: GET_VENUES,
    resultKey: 'venues',
    onSelect: (option: Venue) => {
      if (option && typeof option !== 'string') {
        setSelectedVenue(option);
      }
    },
  };

  const searchComponent = selectedSearchRadio === 'market' ? (
      <SearchMarkets
        search={{
          ...searchProps,
          displayFields: { primary: 'name' },
          resultKey: 'venues',
        }}
      />
  ) : (
      <SearchBar
        search={{
          ...searchProps,
          displayFields: { primary: 'name', secondary: venueRegionDisplayFieldGetter },
          placeholder: 'Search',
          minimumCharacters: 2,
          shouldFlattenVenues: true,
          freeSolo: false,
          resultKey: 'venues',
        }}
      />
  );

  return (
    <ModalContainer data-testid='modal-container'>
      <VenueSearchOptionsHeader data-testid='venue-search-options-header'>
        {searchVenueOptions.map(({ value, label }, index) => {
          const disabled = disableMarketSearch && value === VenueSearchOptions.market;
          const handler = () => setSelectedSearchRadio(value);
          return (
            <RadioOptionContainer key={`radio-option-${label}`}>
              <RadioStyled
                key={`venue-option-${value}-${index}`}
                data-testid={`search-by-${value}`}
                checked={selectedSearchRadio === value}
                value={value}
                disabled={disabled}
                onChange={handler}
              />
              {disabled ? (
                <DisabledText data-testid="disabled-text">{label}</DisabledText>
              ) : (
                <PlainTextButton variant="text" onClick={handler} value={value} disableRipple>
                  <Typography>{label}</Typography>
                </PlainTextButton>
              )}
            </RadioOptionContainer>
          );
        })}
      </VenueSearchOptionsHeader>
      <ListView
        searchComponent={searchComponent}
        datagrid={{
          columns: getVenueGridColumns(onVenueRowClick, venueSelectLabel, onVenueSelect),
          autoHeight: true,
          hideFooter: true,
          rows: selectedGridData,
          showGridInfo: showGridInfo && selectedVenue === null,
          getRowId: (row: { id: string }) => row.id,
          disableRowSelectionOnClick: true,
          onRowClick: (params) => {
            void onVenueRowClick(params);
          },
          slots: {
            noRowsOverlay: () => <></>,
          },
        }}
      />
    </ModalContainer>
  );
}
