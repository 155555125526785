import React from 'react';
import { Button } from '@mui/material';

import { ButtonsContainer } from './ConfirmButtons.styled';

export function ConfirmButtons({
  discard,
  confirm,
  onDiscard,
  onConfirm,
}: {
  discard: string;
  confirm: string;
  onDiscard?: () => void;
  onConfirm?: () => void;
}) {
  return (
    <ButtonsContainer>
      <Button variant="outlined" size='medium' onClick={onDiscard}>
        {discard}
      </Button>
      <Button variant="contained" size='medium' onClick={onConfirm}>
        {confirm}
      </Button>
    </ButtonsContainer>
  );
}
