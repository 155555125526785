import React, { useState } from 'react';
import { GridCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { AddEventInput, Offer, Tour } from '@gql/types/graphql';
import { useAegModeling } from '@hooks';
import dayjs from 'dayjs';

import { VenueDetails } from './VenueDetails';
import { VenueSearch } from '../VenueSearch/VenueSearch';
import { VenueGridData, VenueRowHandler } from '../VenueSearch/VenueSearch.types';
import { VenueSearchContainer } from './AddEventToOffer.styled';

export function AddEventToOffer({ tour, offer }: { tour: Tour; offer: Offer }) {
  const [openDetails, setOpenDetails] = useState<VenueGridData | null>(null);
  const { addEventToOffer } = useAegModeling();

  const handleAddVenue: VenueRowHandler = async ({ row }, spaceConfigurationId): Promise<void> => {
    const { id: spaceId, market, venueId } = row;

    const addEventInput: AddEventInput = {
      companyId: tour.company?.id,
      headliner: tour.headliner?.id,
      name: market,
      spaceId,
      venueId,
    };

    if (tour.coHeadliners) {
      addEventInput.coHeadliners = tour.coHeadliners as string[];
    }
    if (offer.configuration) {
      addEventInput.configuration = offer.configuration;
    }
    if (offer.supports) {
      addEventInput.supports = offer.supports.map((a) => a?.id) as string[];
    }

    await addEventToOffer(addEventInput, spaceConfigurationId);
  };

  const setExpandedVenueDetails = (params: GridRowParams<VenueGridData> | GridCellParams<VenueGridData>) => {
    setOpenDetails({
      ...params.row,
      venueId: params.row.venueId,
      id: params.id as string,
      updatedDate: dayjs(params.row.updatedDate).format('MM/DD/YYYY'),
    });
  };

  return !openDetails ? (
    <VenueSearchContainer>
      <VenueSearch
        showGridInfo
        onVenueRowClick={setExpandedVenueDetails}
        onVenueSelect={handleAddVenue}
        venueSelectLabel="Add"
      />
    </VenueSearchContainer>
  ) : (
    <VenueDetails createEvent={handleAddVenue} openDetails={openDetails} setOpenDetails={setOpenDetails} />
  );
}
