import { GraphQLErrors } from '@apollo/client/errors';
import { useNavigate } from 'react-router-dom';

export enum NavigateErrorType {
  AegContext = 'aegContextMissing',
  NotSynced = 'notSyncedWithModernElvis',
  InitFail = 'initializationFailure',
  CannotLoad = 'cannotLoad',
  FileUpdate = 'templateFileUpdated',
  CloneFail = 'cloneFail',
}

export const useNavigateOnError = () => {
  const navigate = useNavigate();
  return (error?: { message: string; name?: string; graphQLErrors?: GraphQLErrors; type?: NavigateErrorType }) => {
    if (!error) {
      return;
    }

    let gqlError;
    if (error?.graphQLErrors) {
      [gqlError] = error.graphQLErrors;
    }

    // code 1 indicates a 403 error from the GQL server
    if (gqlError && gqlError.extensions.code === 1) {
      navigate('/unauthorized');
    } else if (error.type === NavigateErrorType.AegContext) {
      navigate('/compatibility-warning');
    } else if (error.type === NavigateErrorType.NotSynced) {
      navigate('/not-synced-warning');
    } else if (error.type === NavigateErrorType.InitFail) {
      navigate('/init-fail');
    } else if (error.type === NavigateErrorType.CannotLoad) {
      navigate('/cannot-load');
    } else if (error.type === NavigateErrorType.FileUpdate) {
      navigate('/template-updated-warning');
    } else if (error.type === NavigateErrorType.CloneFail) {
      navigate('/clone-fail');
    } else if (error) {
      navigate('/error', {
        state: {
          status: error.message,
          message: `Error - ${error.name || ''}`,
        },
      });
    }
  };
};
