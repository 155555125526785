/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react';
import { AegAutocomplete, DisplayFields, InputContainer } from '@components';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { useQuery } from '@apollo/client';
import { Venue } from '@gql/types/graphql';
import { flattenVenues } from '@utils/venueHelpers';
import { SearchContainer } from './SearchBar.styled';
import { SearchBarProps, TypenameResponse } from './SearchBar.models';

export function SearchBar<T extends TypenameResponse, K>({ search }: SearchBarProps<T, K>) {
  const [searchTerm, setSearchTerm] = useState('');

  const {
    query,
    displayFields,
    placeholder,
    onSelect,
    resultKey,
    minimumCharacters = 0,
    autoSuggestHighlight = false,
    shouldFlattenVenues = false,
    freeSolo = true,
  } = search;

  const { data: searchResponse, loading: searchLoading } = useQuery(query, {
    variables: { searchTerm },
    errorPolicy: 'all',
    skip: searchTerm.length < minimumCharacters,
  });

  let options: Venue[] | T[keyof T] = [];
  if (searchResponse) {
    options = resultKey === 'venues' && shouldFlattenVenues
      ? flattenVenues(searchResponse[resultKey] as Venue[])
      : searchResponse[resultKey];
  }

  return (
    <SearchContainer>
      <AegAutocomplete
        testId='search-autocomplete'
        options={options as K[]}
        areOptionsLoading={searchLoading}
        dataDisplayField={displayFields as DisplayFields}
        onInputChange={setSearchTerm}
        placeholderText={placeholder}
        minimumCharacters={minimumCharacters}
        autoSuggestHighlight={autoSuggestHighlight}
        InputComponent={InputContainer}
        defaultValue={null}
        value={searchTerm}
        freeSolo={freeSolo}
        onSelect={(...params) => {
          if (onSelect) {
            onSelect(...params, options);
          }
        }}
        inputAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </SearchContainer>
  );
}
