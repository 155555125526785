/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  GridRowId,
  GridColDef,
  GridRowIdGetter,
  GridValidRowModel,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';

import {
  FinancesSupport,
  FinancesHeadliners,
  FinancesFixedCosts,
  FinancesVariableCosts,
  FinancesBoxOfficeTier,
} from '@types';
import { kebabCase } from '@utils/stringHelpers';
import { Accordion, NestedDataGrid, DataGridWithTotals } from '@components';
import { disableColumnInteractions } from '@utils/datagridHelpers';

import { supportColumns, supportEventOfferColumns } from './columns/Support.columns';
import { boxOfficeColumns, boxOfficeEventOfferColumns } from './columns/BoxOffice.columns';
import { headlinersColumns, headlinersEventOfferColumns } from './columns/Headliners.columns';
import { fixedCostsColumns, fixedCostsEventOfferColumns } from './columns/FixedCosts.columns';
import { variableCostsColumns, variableCostsEventOfferColumns } from './columns/VariableCosts.columns';

// TODO: Update with real data
import {
  mockFinancesSupport,
  mockFinancesBoxOffice,
  mockFinancesHeadliners,
  mockFinancesFixedCosts,
  mockFinancesVariableCosts,
} from '../../mocks/mockFinancesData';

type BundleFinancesRow =
  FinancesBoxOfficeTier | FinancesHeadliners | FinancesFixedCosts | FinancesVariableCosts | FinancesSupport;

interface BundleFinancesAccordionProps {
  header: string;
  rowsGroup?: string;
  rows: BundleFinancesRow[];
  columns: GridColDef[];
  getRowId: GridRowIdGetter<BundleFinancesRow>;
  eventOffersDataGrid: {
    columns: GridColDef[];
  };
  totalRows?: FinancesBoxOfficeTier[];
}

export function BundleFinancesTab() {
  const accordionItems: BundleFinancesAccordionProps[] = [
    {
      header: 'Box Office',
      rows: mockFinancesBoxOffice.tiers,
      columns: boxOfficeColumns.map(disableColumnInteractions),
      getRowId: (row) => row.id,
      eventOffersDataGrid: {
        columns: boxOfficeEventOfferColumns,
      },
      totalRows: [
        {
          id: 'box-office-totals-row-id',
          name: 'Avg/Total (All Shows)',
          eventOffers: [],
          ...mockFinancesBoxOffice,
        },
      ],
    },
    {
      header: 'Headliners',
      rows: mockFinancesHeadliners,
      columns: headlinersColumns.map(disableColumnInteractions),
      getRowId: (row) => row.id,
      eventOffersDataGrid: {
        columns: headlinersEventOfferColumns,
      },
    },
    {
      header: 'Support',
      rows: mockFinancesSupport,
      columns: supportColumns.map(disableColumnInteractions),
      getRowId: (row) => row.id,
      eventOffersDataGrid: {
        columns: supportEventOfferColumns,
      },
    },
    {
      header: 'Fixed Costs',
      rowsGroup: 'category',
      rows: mockFinancesFixedCosts,
      columns: fixedCostsColumns.map(disableColumnInteractions),
      getRowId: (row) => row.id,
      eventOffersDataGrid: {
        columns: fixedCostsEventOfferColumns,
      },
    },
    {
      header: 'Variable Costs',
      rows: mockFinancesVariableCosts,
      columns: variableCostsColumns.map(disableColumnInteractions),
      getRowId: (row) => row.id,
      eventOffersDataGrid: {
        columns: variableCostsEventOfferColumns,
      },
    },
  ];

  return accordionItems.map((datagrids) => (
    <Accordion header={datagrids.header} key={`one-off-accordion-${kebabCase(datagrids.header)}`} showDivider>
      <DataGridWithTotals
        rows={datagrids.rows}
        rowsGroup={datagrids.rowsGroup}
        columns={datagrids.columns}
        getRowId={datagrids.getRowId as GridRowIdGetter<GridValidRowModel>}
        testId={kebabCase(datagrids.header)}
        isRowSelectable={() => false}
        scrollable={false}
        getDetailPanelContent={({ row }) => (
          <NestedDataGrid
            rows={(row as BundleFinancesRow).eventOffers}
            columns={datagrids.eventOffersDataGrid.columns}
            hideFooter
            slots={{
              columnHeaders: () => null,
            }}
            isRowSelectable={() => false}
            data-testid={`${kebabCase(datagrids.header)}-event-offers-data-grid`}
            getRowId={(eventOffer: { id: GridRowId }) => eventOffer.id}
            initialState={{
              pinnedColumns: {
                left: ['venueName', 'whiteSpace'],
              },
            }}
          />
        )}
        getDetailPanelHeight={() => 'auto'}
        initialState={{
          pinnedColumns: {
            left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'name'],
          },
        }}
        totalRows={datagrids.totalRows}
      />
    </Accordion>
  ));
}
