/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable max-lines-per-function */
import { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { EVENT_FRAGMENT, SEARCH_EVENTS } from '@gql/queries/events';
import { getTimezoneDate, sortDates } from '@utils/stringHelpers';
import { AltRowColorGrid, SnackbarType, StatusChip } from '@components';
import { getFragmentData } from '@gql/types';
import { GridColDef } from '@mui/x-data-grid-pro';
import { addColumn } from '@utils/datagridHelpers';
import { SearchEventFieldsFragment } from '@gql/types/graphql';
import { useAegModeling } from '@hooks';
import { NotificationDispatchContext } from '@providers';
import dayjs, { Dayjs } from 'dayjs';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
// Un-Comment this block to re-enable row expansion
// import { expandColum, manageExpandedRows } from '@utils/datagridHelpers';
// import {
//   GRID_DETAIL_PANEL_TOGGLE_FIELD,
//   GridColDef,
//   GridEventListener,
//   GridRowId,
//   useGridApiRef,
// } from '@mui/x-data-grid-pro';
// import { TicketTierPanel } from './TicketTierPanel';

interface OfferTabGridData extends SearchEventFieldsFragment {
  numShows?: number | null;
}

export const OfferTab = ({
  venueId,
  spaceId,
  disableVirtualization = false,
}: {
  venueId: string;
  spaceId: string;
  disableVirtualization?: boolean;
}) => {
  const appInsights = useAppInsightsContext();
  const { cloneEvent } = useAegModeling();
  const setNotification = useContext(NotificationDispatchContext);

  // Un-Comment this block to re-enable row expansion
  // const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  // const expandRowClick = (gridRowId: GridRowId) => {
  //   manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  // };

  const columns: GridColDef<OfferTabGridData>[] = [
    // ...expandColumn(expandedRows, expandRowClick), // Un-Comment to re-enable row expansion
    ...addColumn<OfferTabGridData>(async (row) => {
      if (!row.id) {
        return;
      }
      try {
        await cloneEvent(row.id);
      } catch (e) {
        logError(appInsights, 'clone event error', e);
        setNotification({
          type: SnackbarType.ERROR,
          text: 'Event could not be added. Please try again.',
          duration: 6000,
        });
      }
    }),
    // {
    //   field: 'lastUpdatedDate',
    //   headerName: 'Last Updated',
    //   minWidth: 115,
    // },
    {
      field: 'eventDate',
      headerName: 'Event Date',
      minWidth: 115,
      sortComparator: sortDates,
      valueGetter: ({ row }) => {
        if (row.shows?.length) {
          const [firstShow] = row.shows;

          if (firstShow?.showDateTime) {
            return getTimezoneDate(firstShow.showDateTime, row.venue?.timezone)[1];
          }
        }

        return undefined;
      },
      valueFormatter(params) {
        if (!params.value) {
          return 'TBD';
        }

        const sortedDate = params.value as Dayjs;
        return sortedDate.format('L');
      },
    },
    {
      field: 'headliner',
      headerName: 'Headliner',
      minWidth: 130,
      valueGetter: (params) => params.row.headliner?.name,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      minWidth: 110,
    },
    {
      field: 'configuration',
      headerName: 'Configuration',
      minWidth: 125,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => <StatusChip status={params.row.status || ''} />,
    },
    {
      field: 'numShows',
      headerName: '# of Shows',
      minWidth: 100,
      valueGetter: ({ row }) => row.shows?.length,
    },
    {
      field: 'sellableCapacity',
      headerName: 'Sellable Capacity',
      minWidth: 145,
    },
  ];

  const { data, error } = useQuery(SEARCH_EVENTS, {
    errorPolicy: 'ignore',
    variables: {
      filter: {
        startDate: dayjs().subtract(2, 'year').format('YYYY-MM-DD'),
        returnTBD: true, // NOTE: this filter requires startDate to be set
        top: 200,
        venueId,
        spaceId,
      },
    },
  });

  const rows = useMemo<OfferTabGridData[]>(() => {
    if (data?.searchEvents) {
      const { searchEvents: events } = data;

      return events.map((eventData, i) => {
        const event = getFragmentData(EVENT_FRAGMENT, eventData);
        return {
          key: event?.id as string,
          ...event,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          buyer: event?.primaryBuyer?.name as string,
          sellableCapacity: event?.sellableCapacity as number,
          lastUpdatedDate: 'tbd', // temporary
        };
      });
    }

    return [];
  }, [data]);

  // Un-Comment this block to re-enable row expansion
  // const getDetailPanelContent = useCallback(
  //   ({ row }: { row: OfferTabGridData }) => (
  //     <TicketTierPanel
  //       disableVirtualization={disableVirtualization}
  //       defaultBoxOffice={row?.defaultBoxOffice}
  //       currency={row.venue?.localCurrencyCode}
  //     />
  //   ),
  //   [],
  // );
  // const apiRef = useGridApiRef();
  // const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
  //   (params) => {
  //     expandRowClick(params.id);
  //     apiRef.current.toggleDetailPanel(params.id);
  //   },
  //   [apiRef, expandedRows],
  // );

  return (
    data?.searchEvents && (
      <AltRowColorGrid
        hideFooter
        columns={columns}
        rows={rows}
        disableRowSelectionOnClick
        disableVirtualization={disableVirtualization}
        initialState={{
          pinnedColumns: {
            // left: [GRID_DETAIL_PANEL_TOGGLE_FIELD], // Un-Comment to re-enable row expansion
            right: ['addEvent'],
          },
        }}
        // Un-Comment props below to re-enable row expansion
        // apiRef={apiRef}
        // onRowClick={onRowClick}
        // getDetailPanelContent={getDetailPanelContent}
        // getDetailPanelHeight={() => 'auto'
      />
    )
  );
};
