import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';

import {
  VenueNameCell,
  ShowsCountCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import { FinancesSupport, FinancesSupportEventOffer } from '@types';
import { formatNumberToCurrency } from '@utils/numberHelpers';

type ValueGetterParams = GridValueGetterParams<FinancesSupport>;

const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 178,
  maxWidth: 248,
};

const marketTotalsColumn = {
  field: 'marketTotals',
  headerName: 'Market Totals',
  flex: 0.125,
  minWidth: 102,
  valueGetter: ({ row }: ValueGetterParams) => formatNumberToCurrency(row.marketTotals),
};

const amountColumn = {
  field: 'amount',
  headerName: 'Amount',
  flex: 0.125,
  minWidth: 102,
  valueGetter: ({ row }: ValueGetterParams) => formatNumberToCurrency(row.amount),
};

const methodColumn = {
  field: 'method',
  headerName: 'Method',
  flex: 0.125,
  minWidth: 110,
};

const forecastedBonusColumn = {
  field: 'forecastedBonus',
  headerName: 'Forecasted Bonus',
  flex: 0.125,
  minWidth: 110,
  valueGetter: ({ row }: ValueGetterParams) => formatNumberToCurrency(row.forecastedBonus),
};

const selloutBonusColumn = {
  field: 'selloutBonus',
  headerName: 'Sellout Bonus',
  flex: 0.125,
  minWidth: 102,
  valueGetter: ({ row }: ValueGetterParams) => formatNumberToCurrency(row.selloutBonus),
};

type FinancesColumns = GridColDef<FinancesSupport>[];

export const supportColumns: FinancesColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ExpandButtonCell,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell>{row.name}</TextWrapPrettyCell>
        <ShowsCountCell count={row.eventOffers.length} total={row.showsCount} />
      </LeftPinnedCell>
    ),
  },
  marketTotalsColumn,
  amountColumn,
  methodColumn,
  forecastedBonusColumn,
  selloutBonusColumn,
];

type SupportEventOfferColumns = GridColDef<FinancesSupportEventOffer>[];

export const supportEventOfferColumns: SupportEventOfferColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          eventId={row.eventId}
          offerId={row.offerId}
          settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    maxWidth: 10,
  },
  marketTotalsColumn,
  amountColumn,
  methodColumn,
  forecastedBonusColumn,
  selloutBonusColumn,
];
