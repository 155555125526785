/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */

/**
 * Sheet Names
 */
export const VARIABLE_COST_RATES_REF_SHEET_NAME = 'Variable Cost Rates';
export const TEMPLATE_MARKET_SHEET_NAME = 'market_sheet_template';
export const SHOW_DETAIL_SHEET_NAME = 'Show Detail';
export const SUMMARY_SHEET_NAME = 'Summary';
export const SUMMARY_UNCROSSED_SHEET_NAME = 'Summary - Uncrossed';
export const OVERHEAD_COSTS_SHEET_NAME = 'Overhead Costs';
export const QUICK_SUM_SHEET_NAME = 'Quick Sum';
export const LATERAL_SUM_SHEET_NAME = 'Lateral Sum (Potential)';
export const APPROVAL_EMAIL_SHEET_NAME = 'Approval Email';
export const APPROVAL_REVISED_SHEET_NAME = 'Approval Revised';

// Initialize Workbook Key
export const MODELING_INITIALIZED_KEY = 'modeling_workbook_initialized';

export const INACTIVE_TAB_COLOR = 'D7D7D7';
export const DEFAULT_TAB_COLOR = '';

/**
 * Box Office Formulas
 */
export const BOX_OFFICE_SUMMARY = {
  AVAILABLE_CAPACITY: '=[@capacity]-[@kills]',
  SELLABLE: '=[@availableCapacity]-[@comps]',
  PRICE_TO_BASE: '=IFERROR([@price]/ExchangeRate, 0)',
  GROSS: '=[@sellableCapacity]*[@price]',
  GROSS_TO_BASE: '=IFERROR([@grossPotential]/ExchangeRate, 0)',
} as const;

export const BOX_OFFICES = {
  AVAILABLE_CAPACITY: '=[@capacity]-[@kills]',
  SELLABLE: '=[@availableCapacity]-[@comps]',
  GROSS: '=[@sellableCapacity]*[@price]',
} as const;

/**
 * Ancillary Earnings table Formulas
 */
export const ANCILLARY_EARNINGS_PROJECTED = (eventSafeMarketName: string): string => `=IF([@[Calc. Type]]="Flat Rate", [@[Per Ticket]], [@[Per Ticket]]*(${eventSafeMarketName}_Projections[[#Totals],[Sold (Avg.)]]*NumShows))`;

export const ANCILLARY_EARNINGS = {
  GROSS_POTENTIAL: '=IF([@[Calc. Type]]="Flat Rate", [@[Per Ticket]], TotalSellable*[@[Per Ticket]])',
  PER_TICKET_TO_BASE: '=[@[Per Ticket]]/ExchangeRate',
  PROJ_TO_BASE: '=[@Projected]/ExchangeRate',
  POT_TO_BASE: '=[@[Gross Potential]]/ExchangeRate',
  CO_PRO_ONE: '=CoProPotPct1',
  CO_PRO_TWO: '=CoProPotPct2',
} as const;

/**
 * Variable Cost Reference Sheets Tables
 */
export const VARIABLE_COST_RATES_REF_SHEET_TABLES = {
  RATES: 'Rates',
} as const;

/**
 * Tax Fee Formulas
 */
export const TAX_FEE_FORMULAS = {
  PRE_TAX: {
    TOTAL:
      '=IFERROR(IFERROR(SWITCH([@formula], "Divisor",  TotalGross/(1+[@amount])-TotalGross, "Multiplier",TotalGross*(-[@amount]), "Per Ticket", TotalSellable *(-[@amount])), -[@amount]), "")',
    TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
  },
  TAX: {
    TOTAL:
      '=IFERROR(SWITCH([@formula], "Divisor",  SUM(TotalGross,{EVENT_SAFE_SHEET_NAME}_PreTaxFees_0[[#Totals],[total]])/(1+[@amount])-SUM(TotalGross,{EVENT_SAFE_SHEET_NAME}_PreTaxFees_0[[#Totals],[total]]), "Multiplier",SUM(TotalGross,{EVENT_SAFE_SHEET_NAME}_PreTaxFees_0[[#Totals],[total]])*(-[@amount]), "Per Ticket", TotalSellable *(-[@amount])), "")',
    TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
  },
  POST_TAX: {
    TOTAL: '=[@amount]',
    TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
  },
} as const;

/**
 * Variable Costs table Formulas
 */
export const VARIABLE_COSTS_TBL_FORMULAS = {
  CALCULATED_COST: '=VariableCost_Calculation([@name],[@rate],[@minimum],[@maximum])',
  RATE: '=IFERROR(LET(variableRate, XLOOKUP([@name],Rates[Category],Rates[Rate (% NGR)],0), IF(OR([@name]="PRS (Sliding Scale)", [@name]="PRS"), "", IF(OR(AND(OR([@name]="ASCAP (% of Net Gross)", [@name]="BMI (% of Net Gross)", [@name]="GMR (% of Net Gross)", [@name]="SESAC (% of Net Gross)"), Country<>"United States"), AND([@name]="SOCAN (% of Net Gross)", Country<>"Canada")), 0, IF([@name]="Insurance ($ per ticket)", variableRate*ExchangeRate, variableRate)))), 0)',
  COST_TO_BASE: '=IFERROR([@calculatedCost]/ExchangeRate, 0)',
  BLANK_COLUMN_0:
    '=IF(AND(NOT(ISBLANK([@minimum])), NOT(ISBLANK([@maximum]))), IF([@minimum]>[@maximum], "WARNING: Min is greater than max", ""), "")',
  BLANK_COLUMN_1: '',
  BLANK_COLUMN_2: '',
} as const;

/**
 * Fixed Costs
 */
export const FIXED_COSTS_TBL_FORMULAS = {
  BUDGET_TO_BASE: '=IFERROR([@budget]/ExchangeRate, 0)',
} as const;

/**
 * Potential Earnings
 */
export const POTENTIAL_EARNINGS = {} as const;

/**
 * Named Ranges
 */

export const NAMED_RANGES = {
  EVENT_INFO: {
    ARTIST_NAME: 'ArtistName',
    TOUR_NAME: 'TourName',
    MARKET_NAME: 'MarketName',
    COUNTRY: 'Country',
    VENUE_NAME: 'VenueName',
    NUM_SHOWS: 'NumShows',
    NUM_TRUCKS: 'NumTrucks',
    STAGE_HANDS_COST_PER_TRUCK: 'CostPerTruck',
    STATUS: 'Status',
    LOCAL_CURRENCY: 'LocalCurrency',
    BASE_CURRENCY: 'BaseCurrency',
    EXCHANGE_RATE: 'ExchangeRate',
    EVENT_SELLABLE_CAPACITY: 'EventSellableCapacity',
    COMP_BREAKDOWN: 'CompBreakdown',
    MERCHANDISE_DEAL: 'MerchandiseDeal',
    NOTES_AND_COMMENTS: 'NotesComments',
    DEAL_LANGUAGE: 'DealLanguage',
    SHOW_DATE: 'ShowDate',
    PROJECTED_PCT_SOLD: 'ProjectedPctSold',
    TOTAL_CAPACITY: 'TotalCapacity',
    TOTAL_COSTS_TO_BASE: 'TotalCostsToBase',
    TOTAL_AVAILABLE_CAPACITY: 'TotalAvailableCapacity',
    TOTAL_SELLABLE: 'TotalSellable',
    TOTAL_GROSS: 'TotalGross',
    TOTAL_GROSS_TO_BASE: 'TotalGrossToBase',
    TOTAL_VARIABLE_COSTS_TO_BASE: 'TotalVariableCostsToBase',
    POST_TAX_ADJUSTMENTS_TO_BASE: 'PostTaxAdjustmentsToBase',
    NET_GROSS_RECEIPTS: 'NetGrossReceipts',
    NET_GROSS_RECEIPTS_TO_BASE: 'NetGrossReceiptsToBase',
    BUDGETED_SHOW_EXPENSES: 'BudgetedShowExpenses',
    BUDGETED_SHOW_EXPENSES_TO_BASE: 'BudgetedShowExpensesToBase',
    SELL_OFF_ADJUSTMENT: 'SellOffAdjustment',
    SELL_OFF_ADJUSTMENT_TO_BASE: 'SellOffAdjustmentToBase',
    NET_SHOW_RECEIPTS: 'NetShowReceipts',
    NET_SHOW_RECEIPTS_TO_BASE: 'NetShowReceiptsToBase',
    GUARANTEE: 'Guarantee',
    GUARANTEE_TO_BASE: 'GuaranteeToBase',
    PCT_NET_POOL_EARNINGS: 'PctNetPoolEarnings',
    NET_POOL_EARNINGS: 'NetPoolEarnings',
    NET_POOL_EARNINGS_TO_BASE: 'NetPoolEarningsToBase',
    ARTIST_ADJ_NOTES: 'ArtistAdjNotes',
    ARTIST_ADJUSTMENT: 'ArtistAdjustment',
    ARTIST_ADJUSTMENT_TO_BASE: 'ArtistAdjustmentToBase',
    ARTIST_EARNINGS: 'ArtistEarnings',
    ARTIST_EARNINGS_TO_BASE: 'ArtistEarningsToBase',
    // Projected / Actual
    SEQUENTIAL_SALES: 'SequentialSales',
    SEQUENTIAL_GROSS_SHOW_RECEIPTS: 'SequentialGrossShowReceipts',
    SEQUENTIAL_NET_GROSS_RECEIPTS: 'SequentialNetGrossReceipts',
    SEQUENTIAL_TOTAL_SHOW_COSTS: 'SequentialTotalShowCosts',
    SEQUENTIAL_NET_TO_POOL: 'SequentialNetToPool',
    ARTIST_EARNINGS_SEQ_ROUNDED: 'ArtistEarningsSeqRounded',
    TOTAL_ANCILLARY_PROJECTED_TO_BASE: 'TotalAncillaryProjectedToBase',
    SEQUENTIAL_CO_PRO_SHARE: 'SequentialCoProShare',
    SEQUENTIAL_AEG_NET_SHOW_EARNINGS: 'SequentialAegNetShowEarnings',
    // Costings
    SHOW_INCOME_SEQ_VARIABLE_COSTS: 'ShowIncomeSeqVariableCosts',
    PROJECTED_TOTAL_SOLD_SEQ: 'ProjectedTotalSoldSeq',
    TOTAL_ANCILLARY_PROJECTED: 'TotalAncillaryProjected',
    TOTAL_ANCILLARY_POTENTIAL: 'TotalAncillaryPotential',
    CO_PRO_1_POTENTIAL_PERCENT: 'CoProPotPct1',
    CO_PRO_2_POTENTIAL_PERCENT: 'CoProPotPct2',
    SELL_OFF_CALC_POTENTIAL_GUARANTEE: 'SellOffPotGuarantee',
    SELL_OFF_CALC_POTENTIAL_VS_PERCENT: 'SellOffPotVsPct',
    SELL_OFF_CALC_POTENTIAL_INCOME_POOL: 'SellOffPotIncomePool',
    FIXED_COSTS_TOTALS: [
      { categoryId: 'Advertising', names: { totalBudgetName: 'TotalAdvertising', totalBudgetToBaseName: 'TotalAdvertisingToBase' } },
      { categoryId: 'Artist Prorates', names: { totalBudgetName: 'TotalArtistProrates', totalBudgetToBaseName: 'TotalArtistProratesToBase' } },
      { categoryId: 'Catering', names: { totalBudgetName: 'TotalCatering', totalBudgetToBaseName: 'TotalCateringToBase' } },
      { categoryId: 'Other Costs', names: { totalBudgetName: 'TotalOtherCosts', totalBudgetToBaseName: 'TotalOtherCostsToBase' } },
      { categoryId: 'Production', names: { totalBudgetName: 'TotalProduction', totalBudgetToBaseName: 'TotalProductionToBase' } },
      { categoryId: 'Production Prorates 1', names: { totalBudgetName: 'TotalProductionProrates', totalBudgetToBaseName: 'TotalProductionProratesToBase' } },
      { categoryId: 'Rent', names: { totalBudgetName: 'TotalRent', totalBudgetToBaseName: 'TotalRentToBase' } },
      { categoryId: 'Staffing', names: { totalBudgetName: 'TotalStaffing', totalBudgetToBaseName: 'TotalStaffingToBase' } },
      { categoryId: 'Stagehands', names: { totalBudgetName: 'TotalStagehands', totalBudgetToBaseName: 'TotalStagehandsToBase' } },
      { categoryId: 'Support', names: { totalBudgetName: 'TotalSupport', totalBudgetToBaseName: 'TotalSupportToBase' } },
      { categoryId: 'Band Transport', names: { totalBudgetName: 'TotalTransport', totalBudgetToBaseName: 'TotalTransportToBase' } },
      { categoryId: 'Venue', names: { totalBudgetName: 'TotalVenue', totalBudgetToBaseName: 'TotalVenueToBase' } },
    ],
    LOCAL_CURRENCY_VARIABLE_COSTS_RATES: 'LocalCurrencyMarketVariableCostsRates',
    BASE_CURRENCY_PARENT_DECIMAL: [ // parent range
      'BaseCurrencyMarketBoxOffice',
      'BaseCurrencyMarketAncillaryAmount',
    ],
    BASE_CURRENCY_PARENT_ROUNDED: [ // parent range
      'BaseCurrencyMarketFixedCosts',
      'BaseCurrencyMarketVariableCosts',
      'BaseCurrencyMarketAdjustments',
      'BaseCurrencyMarketPotentialEarnings',
      'BaseCurrencyMarketAncillaryTotals',
      'BaseCurrencyMarketBreakEvenCalculations',
    ],
    LOCAL_CURRENCY_PARENT_DECIMAL: [ // parent range
      'LocalCurrencyMarketBoxOffice',
      'LocalCurrencyMarketAdjustments',
      'LocalCurrencyMarketShowSummaryAvgTicketPrice',
      'LocalCurrencyMarketAncillaryAmount',
      'LocalCurrencyMarketPotentialEarnings',
    ],
    LOCAL_CURRENCY_PARENT_ROUNDED: [ // parent range
      'LocalCurrencyMarketVariableCosts',
      'LocalCurrencyMarketFixedCosts',
      'LocalCurrencyMarketPotentialShowSummary',
      'LocalCurrencyMarketAvgShowSummary',
      'LocalCurrencyMarketSeqShowSummary',
      'LocalCurrencyMarketAncillaryTotals',
      'LocalCurrencyBreakEvenCalculations',
      'LocalCurrencyMarketEventInfoPerTruckCost',
    ],
    BREAK_EVEN_CALCULATIONS: {
      BREAK_EVEN_SEQ_OTHER: 'BreakEvenSeqOther',
    },
    SELL_OFF_CALCULATIONS: {
      SELL_OFF_ADJUSTMENTS_POOL: 'SellOffSeqAdjustmentsPool',
    },
  },
  ARTIST_OFFER: {
    GUARANTEE: 'Guarantee',
    NET_POOL_EARN: 'PctNetPoolEarnings',
    ADJ_NOTES: 'ArtistAdjNotes',
    ADJUSTMENT: 'ArtistAdjustment',
  },
  SUMMARY: {
    TOUR_NAME: 'WorkbookTourName',
    ARTIST_NAME: 'WorkbookArtistName',
    BASE_CURRENCY: 'WorkbookBaseCurrency',
    NUM_TRUCKS: 'WorkbookNumTrucks',
    BASE_CURRENCY_FIELDS: 'SummaryBaseCurrencyFields',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencySummaryDecimal',
    BASE_CURRENCY_ROUNDED: 'BaseCurrencySummaryRounded',
    BASE_CURRENCY_AVG_TICKET_PRICE: 'BaseCurrencySummaryAvgTicketPrice',
    TOTAL_POOL_INCOME: {
      MAX_MANUAL_ADJUSTMENTS: 8,
      TICKETING_REVENUE_AVG: 'TotalPoolIncomeTicketingRevenue_Avg',
      SPONSORSHIP_REVENUE_AVG: 'TotalPoolIncomeSponsorshipRevenue_Avg',

      // PREMIUM: {
      //   // GROSS: 'TotalPoolIncomeTicketingRevenue_Gross',
      //   AVG: 'TotalPoolIncomeTicketingRevenue_Avg',
      //   // SEQ: 'TotalPoolIncomeTicketingRevenue_Seq',
      //   // BLENDED: 'TotalPoolIncomeTicketingRevenue_Blended',
      // },
      // SPONSORSHIP: {
      //   // GROSS: 'TotalPoolIncomeSponsorshipRevenue_Gross',
      //   AVG: 'TotalPoolIncomeSponsorshipRevenue_Avg',
      //   // SEQ: 'TotalPoolIncomeSponsorshipRevenue_Seq',
      //   // BLENDED: 'TotalPoolIncomeSponsorshipRevenue_Blended',
      // },
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalPoolIncomeManualAdjustment_Text_',
        // GROSS: 'TotalPoolIncomeManualAdjustment_Gross_',
        AVG: 'TotalPoolIncomeManualAdjustment_Avg_',
        // SEQ: 'TotalPoolIncomeManualAdjustment_Seq_',
        // BLENDED: 'TotalPoolIncomeManualAdjustment_Blended_',
      },
    },
    TOTAL_ARTIST_EARNINGS: {
      MAX_MANUAL_ADJUSTMENTS: 5,
      SPLIT_POINT_PERCENT: 'TotalArtistEarningsSplitPointPercent',
      PERCENT_OVER_SPLIT_POINT: 'TotalArtistEarningsPercentOverSplitPoint',
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalArtistEarningsManualAdjustment_Text_',
        // GROSS: 'TotalArtistEarningsManualAdjustment_Gross_',
        AVG: 'TotalArtistEarningsManualAdjustment_Avg_',
        // SEQ: 'TotalArtistEarningsManualAdjustment_Seq_',
        // BLENDED: 'TotalArtistEarningsManualAdjustment_Blended_',
      },
    },
    NET_GROSS_PROMOTER_INCOME: {
      MAX_MANUAL_ADJUSTMENTS: 4,
      // ANCILLARY: {
      //   NAME: 'NetGrossPromoterIncomeAncillaryRevenue_Text',
      //   GROSS: 'NetGrossPromoterIncomeAncillaryRevenue_Gross',
      //   AVG: 'NetGrossPromoterIncomeAncillaryRevenue_Avg',
      //   SEQ: 'NetGrossPromoterIncomeAncillaryRevenue_Seq',
      //   BLENDED: 'NetGrossPromoterIncomeAncillaryRevenue_Blended',
      // },
      MANUAL_ADJUSTMENTS: {
        NAME: 'NetGrossPromoterIncomeManualAdjustment_Text_',
        // GROSS: 'NetGrossPromoterIncomeManualAdjustment_Gross_',
        AVG: 'NetGrossPromoterIncomeManualAdjustment_Avg_',
        // SEQ: 'NetGrossPromoterIncomeManualAdjustment_Seq_',
        // BLENDED: 'NetGrossPromoterIncomeManualAdjustment_Blended_',
      },
    },
    // BREAK_EVEN: {
    //   MANUAL_ADJUSTMENTS: {
    //     NAME: 'BreakEvenCalculationManualAdjustment_Text_',
    //     AVG: 'BreakEvenCalculationManualAdjustment_Avg_',
    //     SEQ: 'BreakEvenCalculationManualAdjustment_Seq_',
    //     BLENDED: 'BreakEvenCalculationManualAdjustment_Blended_',
    //   },
    // },
  },
  SUMMARY_UNCROSSED: {
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyUncrossedSummaryDecimal',
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyUncrossedSummaryRounded',
    BASE_CURRENCY_AVG_TICKET_PRICE: 'BaseCurrencyUncrossedSummaryAvgTicketPrice',
  },
  QUICK_SUM: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyQuickSumRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyQuickSumDecimal',
  },
  LATERAL_SUM_POTENTIAL: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyLateralSumRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyLateralSumDecimal',
  },
  SHOW_DETAIL: {
    BASE_CURRENCY_ROUNDED: [ // parent range
      'BaseCurrencyShowDetailCoProEquation',
      'BaseCurrencyShowDetailFixedCosts',
      'BaseCurrencyShowDetailPotentialRevenue',
      'BaseCurrencyShowDetailProjectedResults',
      'BaseCurrencyShowDetailSellOff',
      'BaseCurrencyShowDetailShowByShow',
      'BaseCurrencyShowDetailTierScaling',
      'BaseCurrencyShowDetailVariableCosts',
      'BaseCurrencyBelowShowDetailCoProEquation',
      'BaseCurrencyBelowShowDetailFixedCosts',
      'BaseCurrencyBelowShowDetailPotentialRevenue',
      'BaseCurrencyBelowShowDetailProjectedResults',
      'BaseCurrencyBelowShowDetailSellOff',
      'BaseCurrencyBelowShowDetailShowByShow',
      'BaseCurrencyBelowShowDetailTierScaling',
      'BaseCurrencyBelowShowDetailVariableCosts',
    ],
    BASE_CURRENCY_DECIMAL: [ // parent range
      'BaseCurrencyShowDetailTierScalingPrice',
      'BaseCurrencyBelowShowDetailTierScalingPrice',
    ],
  },
  OVERHEAD_COSTS: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyOverheadCostsRounded',
  },
  APPROVAL_EMAIL: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyApprovalEmailRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyApprovalEmailDecimal',
    BASE_CURRENCY_DECIMAL_TEST: 'BaseCurrencyApprovalEmailDecimalTest',
  },
  APPROVAL_REVISED: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyApprovalRevisedRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyApprovalRevisedDecimal',
  },
} as const;

export const MARKET_SHEET_TABLE_IDENTIFIERS = {
  BOX_OFFICE: 'BoxOffice',
  PRE_TAX_FEES: 'PreTaxFees',
  TAXES: 'Taxes',
  POST_TAX_FEES: 'PostTaxFees',
  VARIABLE_COSTS: 'VariableCosts',
  ANCILLARY_EARNINGS: 'Ancillary',
};

export const SHEET_NAME_KEY = '{EVENT_SHEET_NAME}';

export const MINIMUM_BOX_OFFICE_ROWS = 15;

/**
 * Show Detail
 *    Keep this one last...
 */

export const SHOW_DETAIL_TABLE_NAME = 'ShowDetail';
export const BELOW_SHOW_DETAIL_TABLE_NAME = 'BelowShowDetail';
export const SAFE_SHEET_NAME_KEY = '{EVENT_SAFE_SHEET_NAME}';
export const SHOW_DETAIL_COLUMN_LENGTH = 244;
export const CREATE_SHOW_DETAIL_ROW = (eventSheetName: string, eventSafeMarketName: string): string[] => [
  `${eventSheetName}`, // DEPRECATED: Used by old workbooks
  '', // View Sheet column, removed in SANE-6254
  `='${eventSheetName}'!Status`,
  `='${eventSheetName}'!ShowDate`,
  `='${eventSheetName}'!MarketName`,
  `='${eventSheetName}'!VenueName`,
  `=${eventSafeMarketName}_BoxOffice_0[[#Totals],[sellableCapacity]]`,
  `='${eventSheetName}'!NumShows`,
  '=+[@[Num Shows]]*[@[Per Show Capacity]]',
  '=IFERROR([@[Total Capacity]]-[@[Seats 1]]-[@[Seats 2]]-[@[Seats 3]]-[@[Seats 4]]-[@[Seats 5]]-[@[Seats 6]]-[@[Seats 7]]-[@[Seats 8]]-[@[Seats 9]]-[@[Seats 10]]-[@[Seats 11]]-[@[Seats 12]]-[@[Seats 13]]-[@[Seats 14]]-[@[Seats 15]]-[@[Seats 16+]], 0)',
  `='${eventSheetName}'!LocalCurrency`,
  `='${eventSheetName}'!ExchangeRate`,
  '=IFERROR([@[Seats 1]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],1)/[@[Exchange Rate]], 0)`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],1), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 1]]*[@[Price 1]], 0)',
  '=IFERROR([@[Seats 2]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],2)/[@[Exchange Rate]], 0)`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],2), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 2]]*[@[Price 2]], 0)',
  '=IFERROR([@[Seats 3]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],3)/[@[Exchange Rate]], 0)`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],3), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 3]]*[@[Price 3]], 0)',
  '=IFERROR([@[Seats 4]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],4)/[@[Exchange Rate]], 0)`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],4), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 4]]*[@[Price 4]], 0)',
  '=IFERROR([@[Seats 5]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],5)/[@[Exchange Rate]], 0)`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],5), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 5]]*[@[Price 5]], 0)',
  '=IFERROR([@[Seats 6]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],6)/[@[Exchange Rate]], 0)`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],6), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 6]]*[@[Price 6]], 0)',
  '=IFERROR([@[Seats 7]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],7), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],7), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 7]]*[@[Price 7]], 0)',
  '=IFERROR([@[Seats 8]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],8), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],8), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 8]]*[@[Price 8]], 0)',
  '=IFERROR([@[Seats 9]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],9), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],9), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 9]]*[@[Price 9]], 0)',
  '=IFERROR([@[Seats 10]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],10), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],10), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 10]]*[@[Price 10]], 0)',
  '=IFERROR([@[Seats 11]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],11), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],11), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 11]]*[@[Price 11]], 0)',
  '=IFERROR([@[Seats 12]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],12), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],12), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 12]]*[@[Price 12]], 0)',
  '=IFERROR([@[Seats 13]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],13), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],13), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 13]]*[@[Price 13]], 0)',
  '=IFERROR([@[Seats 14]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],14), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],14), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 14]]*[@[Price 14]], 0)',
  '=IFERROR([@[Seats 15]]/[@[Total Capacity]], 0)',
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],15), 0)/[@[Exchange Rate]]`,
  `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],15), 0)*[@[Num Shows]]`,
  '=IFERROR(+[@[Seats 15]]*[@[Price 15]], 0)',
  '=IFERROR([@[Seats 16+]]/[@[Total Capacity]], 0)',
  '',
  `=IFERROR(IF(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])>15, SUM(TAKE(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],-(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])-15))), 0), 0)*[@[Num Shows]]`,
  `=IFERROR(IF(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])>15, SUM(MAP(TAKE(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],-(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])-15)), TAKE(${eventSafeMarketName}_BoxOffice_0[price],-(ROWS(${eventSafeMarketName}_BoxOffice_0[price])-15)), LAMBDA(sellable,price, sellable*price)))/[@[Exchange Rate]], 0), 0)*[@[Num Shows]]`,
  '=IFERROR(SUM([@[Sub-Total 1]],[@[Sub-Total 2]],[@[Sub-Total 3]],[@[Sub-Total 4]],[@[Sub-Total 5]],[@[Sub-Total 6]],[@[Sub-Total 7]],[@[Sub-Total 8]],[@[Sub-Total 9]], [@[Sub-Total 10]],[@[Sub-Total 11]],[@[Sub-Total 12]],[@[Sub-Total 13]],[@[Sub-Total 14]],[@[Sub-Total 15]],[@[Sub-Total 16+]])-[@[Potential Gross]], 0)',
  `=IFERROR('${eventSheetName}'!TotalGross/[@[Exchange Rate]], 0)`,
  `=IFERROR(SUM(SUMIF(${eventSafeMarketName}_Taxes_0[formula],"Divisor",${eventSafeMarketName}_Taxes_0[amount]), SUMIF(${eventSafeMarketName}_Taxes_0[formula],"Multiplier",${eventSafeMarketName}_Taxes_0[amount])), 0)`,
  `=IFERROR(SUM(SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"FMF",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Charity",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Parking",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Pre Tax Other 1",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Pre Tax Other 2",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Pre Tax Other 3",${eventSafeMarketName}_PreTaxFees_0[amount])), 0)`,
  `=IFERROR('${eventSheetName}'!NetGrossReceipts/[@[Exchange Rate]], 0)`,
  `=[@[Potential Net]]-('${eventSheetName}'!NetGrossReceipts/[@[Exchange Rate]])`,
  `=IFERROR(IF('${eventSheetName}'!ProjectedPctSold <= 0 , '${eventSheetName}'!ProjectedTotalSoldAvg/'${eventSheetName}'!TotalSellable, '${eventSheetName}'!ProjectedPctSold), 0)`,
  '',
  '=IFERROR([@[Projected Avg. Fcast Attend]]/[@[Total Capacity]], 0)',
  '=IF([@[Projected Avg. Pct.]]>0,[@[Projected Avg. Pct.]]*[@[Total Capacity]],[@[Projected Avg. Tickets Sold]])',
  `=(${eventSafeMarketName}_Projections[[#Totals],[Gross (Avg.)]]*'${eventSheetName}'!NumShows)/[@[Exchange Rate]]`,
  `='${eventSheetName}'!ShowIncomeAvgNetGrossReceipts/[@[Exchange Rate]]`,
  `='${eventSheetName}'!ShowIncomeSeqGrossReceipts/[@[Exchange Rate]]`,
  `='${eventSheetName}'!ShowIncomeSeqNetGrossReceipts/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Prorate[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_ProdProrate[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Support[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Rent[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Stagehands[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Staffing[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Catering[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Production[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Venue[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Advertising[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_Transport[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  `=${eventSafeMarketName}_FixedCostCategory_OtherCosts[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  '=SUM([@[Artist S&L]], [@[Production Prorates]],[@Support],[@Rent],[@Stagehands],[@Staffing],[@Catering],[@Production],[@Venue],[@Advertising],[@[Band Transport]],[@[Misc. Costs]])',
  `=[@[Total Fixed Costs]]-'${eventSheetName}'!FixedCostsSubtotal/[@[Exchange Rate]]`,
  `=IFERROR(SUM(SUMIFS(${eventSafeMarketName}_ProjectedCosts[Projected Average],${eventSafeMarketName}_ProjectedCosts[Expenses],{"Rent (% of Gross)","Rent (% of Net Gross)"})), 0)`,
  `=IFERROR(SUM(SUMIFS(${eventSafeMarketName}_ProjectedCosts[Projected Sequential],${eventSafeMarketName}_ProjectedCosts[Expenses],{"Rent (% of Gross)","Rent (% of Net Gross)"})), 0)`,
  `=IFERROR(SUM(SUMIFS(${eventSafeMarketName}_VariableCosts[calculatedCost],${eventSafeMarketName}_VariableCosts[name],{"Rent (% of Gross)","Rent (% of Net Gross)"})), 0)`,
  `=IFERROR(-'${eventSheetName}'!ShowIncomeAvgVariableCosts/[@[Exchange Rate]], 0)`,
  `=IFERROR(-'${eventSheetName}'!ShowIncomeSeqVariableCosts/[@[Exchange Rate]], 0)`,
  `=IFERROR(${eventSafeMarketName}_VariableCosts[[#Totals],[calculatedCost]]/[@[Exchange Rate]], 0)`,
  '=IFERROR([@[Total Fixed Costs]]+[@[Total Variable Projected Avg.]], 0)',
  '=IFERROR([@[Total Fixed Costs]]+[@[Total Variable Projected Seq.]], 0)',
  '=IFERROR([@[Total Fixed Costs]]+[@[Total Variable Sellout]], 0)',
  `=SUM([@[Total Expenses Sellout]],('${eventSheetName}'!ShowIncomePotVariableCosts/[@[Exchange Rate]]))`,
  '=IFERROR([@[Projected Avg. Fcast Net]]-[@[Total Expenses Avg.]]+[@[Sell-Off Adj. Avg.]], 0)',
  "=IFERROR([@[Projected Net Receipts '@ Avg.]]/[@[Projected Avg. Fcast Net]], 0)",
  `=[@[Projected Net Receipts '@ Avg.]]-'${eventSheetName}'!ShowIncomeAvgPoolToSplit`,
  '=[@[Projected Seq. Fcast Net]]-[@[Total Expenses Seq.]]+[@[Sell-Off Adj. Seq.]]',
  "=IFERROR([@[Projected Net Receipts '@ Seq.]]/[@[Projected Seq. Fcast Net]], 0)",
  `=[@[Projected Net Receipts '@ Seq.]]-('${eventSheetName}'!ShowIncomeSeqPoolToSplit/[@[Exchange Rate]])`,
  '=IFERROR(+[@[Potential Net]]-[@[Total Expenses Sellout]]+[@[Sell-Off Adj. Potential]], 0)',
  "=IFERROR(IF([@[Potential Net]]=0,0,[@[Projected Net Receipts '@ Sellout]]/[@[Potential Net]]), 0)",
  `=[@[Projected Net Receipts '@ Sellout]]-('${eventSheetName}'!ShowIncomePotPoolToSplit/[@[Exchange Rate]])`,
  `=IFERROR('${eventSheetName}'!Guarantee/[@[Exchange Rate]], 0)`,
  `=IFERROR(IF(('${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Sellout]])>[@Guarantee], '${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Sellout]], [@Guarantee]), 0)`,
  "=IFERROR([@[Projected Net Receipts '@ Sellout]]-[@[Gross Artist Earnings]], 0)",
  `=IFERROR(IF(('${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Avg.]])>[@Guarantee],'${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Avg.]],[@Guarantee]), 0)`,
  "=IFERROR([@[Projected Net Receipts '@ Avg.]]-[@[Avg. Artist Earnings]], 0)",
  `=IFERROR(IF(('${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Seq.]])>[@Guarantee],'${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Seq.]],[@Guarantee]), 0)`,
  "=[@[Projected Net Receipts '@ Seq.]]-[@[Seq. Artist Earnings]]",
  `=IF([@[Exchange Rate]]=0,${eventSafeMarketName}_Ancillary[[#Totals],[Projected]], ${eventSafeMarketName}_Ancillary[[#Totals],[Projected]]/[@[Exchange Rate]])`,
  `=IF([@[Exchange Rate]]=0,${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]],${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]]/[@[Exchange Rate]])`,
  `=IF('${eventSheetName}'!SellOffPotGuarantee>0,'${eventSheetName}'!NumShows,0)`,
  `='${eventSheetName}'!SellOffPotGuarantee/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffPotVsPct`,
  `='${eventSheetName}'!SellOffPotIncomePool`,
  `='${eventSheetName}'!SellOffPotEarningsPool/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffAvgEarningsPool/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffSeqEarningsPool/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffPotAdjustmentsPool/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffAvgAdjustmentsPool/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffSeqAdjustmentsPool/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffPotBreakEvenAdj/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffAvgBreakEvenAdj/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffSeqBreakEvenAdj/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffPotNetShowReceipts/[@[Exchange Rate]]`,
  '',
  '',
  '',
  `='${eventSheetName}'!SellOffAvgPurchaserPays/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffSeqPurchaserPays/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffPotPurchaserEarning/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffAvgPurchaserEarning/[@[Exchange Rate]]`,
  `='${eventSheetName}'!SellOffSeqPurchaserEarning/[@[Exchange Rate]]`,
  `=IF(OR('${eventSheetName}'!CoProPotPct1>0, '${eventSheetName}'!CoProPotPct2>0), '${eventSheetName}'!NumShows, 0)`,
  `=IF([@[CoPro NumShows]]>0, '${eventSheetName}'!CoProPotSoftRent1/[@[Exchange Rate]], 0)`,
  `=IF([@[CoPro NumShows]]>0, '${eventSheetName}'!CoProPotSoftRent2/[@[Exchange Rate]], 0)`,
  `=SUM('${eventSheetName}'!ShowIncomePotPoolToSplit, ${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]])/[@[Exchange Rate]]`,
  `=SUM('${eventSheetName}'!ShowIncomeAvgPoolToSplit, ${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]])/[@[Exchange Rate]]`,
  `=SUM('${eventSheetName}'!ShowIncomeSeqPoolToSplit, ${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]])/[@[Exchange Rate]]`,
  `=IF([@[CoPro NumShows]]>0, 1-'${eventSheetName}'!CoProPotPct1-'${eventSheetName}'!CoProPotPct2, 0)`,
  `=IF([@[CoPro NumShows]]>0, '${eventSheetName}'!CoProPotGuarantee1/[@[Exchange Rate]], 0)`,
  `=LET(a,'${eventSheetName}'!CoProPotArtistPct1,b,'${eventSheetName}'!CoProPotArtistPct2, IFERROR(SUM(a,b)/(AND(a<>0,a<>"")+AND(b<>0,b<>"")), 0))`,
  `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProPotArtistEarnings1/[@[Exchange Rate]], 0)`,
  `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProAvgArtistEarnings1/[@[Exchange Rate]], 0)`,
  `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProSeqArtistEarnings1/[@[Exchange Rate]], 0)`,
  `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProPotNetToSplit1/[@[Exchange Rate]], 0)`,
  `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProAvgNetToSplit1/[@[Exchange Rate]], 0)`,
  `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProSeqNetToSplit1/[@[Exchange Rate]], 0)`,
  `=SUM('${eventSheetName}'!CoProPotPartnerShare1, '${eventSheetName}'!CoProPotPartnerShare2)/[@[Exchange Rate]]`,
  `=SUM('${eventSheetName}'!CoProAvgPartnerShare1, '${eventSheetName}'!CoProAvgPartnerShare2)/[@[Exchange Rate]]`,
  `=SUM('${eventSheetName}'!CoProSeqPartnerShare1, '${eventSheetName}'!CoProSeqPartnerShare2)/[@[Exchange Rate]]`,
  `='${eventSheetName}'!CoProPotAEGShareGain/[@[Exchange Rate]]`,
  `='${eventSheetName}'!CoProAvgAEGShareGain/[@[Exchange Rate]]`,
  `='${eventSheetName}'!CoProSeqAEGShareGain/[@[Exchange Rate]]`,
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '=[@[Projected Avg. Fcast Attend]]',
  '=IF([@[Total Sales]]>[@[Seats 1]],[@[Seats 1]],[@[Total Sales]])',
  '=IF([@[P1 Sales]]<[@[Seats 1]],0,IF(([@[Seats 1]]+[@[Seats 2]])<[@[Total Sales]],[@[Seats 2]],[@[Total Sales]]-[@[P1 Sales]]))',
  '=IF([@[P2 Sales]]<[@[Seats 2]],0,IF(([@[P1 Sales]]+[@[P2 Sales]]+[@[Seats 3]])<[@[Total Sales]],[@[Seats 3]],([@[Total Sales]]-[@[P1 Sales]]-[@[P2 Sales]])))',
  '=IF([@[P3 Sales]]<[@[Seats 3]],0,(IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]])+[@[Seats 4]])<[@[Total Sales]],[@[Seats 4]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]])))))',
  '=IF([@[P4 Sales]]<[@[Seats 4]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]])+[@[Seats 5]])<[@[Total Sales]],[@[Seats 5]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]]))))',
  '=IF([@[P5 Sales]]<[@[Seats 5]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]])+[@[Seats 6]])<[@[Total Sales]],[@[Seats 6]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]]))))',
  '=IF([@[P6 Sales]]<[@[Seats 6]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]])+[@[Seats 7]])<[@[Total Sales]],[@[Seats 7]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]]))))',
  '=IF([@[P7 Sales]]<[@[Seats 7]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]])+[@[Seats 8]])<[@[Total Sales]],[@[Seats 8]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]]))))',
  '=IF([@[P8 Sales]]<[@[Seats 8]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]])+[@[Seats 9]])<[@[Total Sales]],[@[Seats 9]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]]))))',
  '=IF([@[P9 Sales]]<[@[Seats 9]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]])+[@[Seats 10]])<[@[Total Sales]],[@[Seats 10]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]]))))',
  '=IF([@[P10 Sales]]<[@[Seats 10]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]])+[@[Seats 11]])<[@[Total Sales]],[@[Seats 11]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]]))))',
  '=IF([@[P11 Sales]]<[@[Seats 11]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]])+[@[Seats 12]])<[@[Total Sales]],[@[Seats 12]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]]))))',
  '=IF([@[P12 Sales]]<[@[Seats 12]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]])+[@[Seats 13]])<[@[Total Sales]],[@[Seats 13]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]]))))',
  '=IF([@[P13 Sales]]<[@[Seats 13]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]])+[@[Seats 14]])<[@[Total Sales]],[@[Seats 14]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]]))))',
  '=IF([@[P14 Sales]]<[@[Seats 14]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]])+[@[Seats 15]])<[@[Total Sales]],[@[Seats 15]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]]))))',
  '=IF([@[P15 Sales]]<[@[Seats 15]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]], [@[Seats 15]])+[@[Seats 16+]])<[@[Total Sales]],[@[Seats 16+]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]], [@[Seats 15]]))))',
  '=SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]],[@[P9 Sales]],[@[P10 Sales]],[@[P11 Sales]],[@[P12 Sales]],[@[P13 Sales]],[@[P14 Sales]],[@[P15 Sales]],[@[P16+ Sales]])-[@[Total Sales]]',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
];
