/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useMemo, useState } from 'react';
import { AegAutocomplete, InputContainer } from '@components';
import { GET_COUNTRIES } from '@gql/queries/venues';
import { useQuery } from '@apollo/client';
import { Country, State } from '@gql/types/graphql';
import {
  SearchContainer,
  SearchContainerMarket,
  StateCityContainer,
  SearchStateCity,
} from './SearchMarkets.styled';
import { SearchProps } from './SearchMarkets.models';
import { orderCountries } from './SearchMarket.helpers';

interface SearchMarketsProps<T> {
  search: SearchProps<T>;
}

export function SearchMarkets<T>({ search }: SearchMarketsProps<T>) {
  const {
    query,
    displayFields,
    onSelect,
    resultKey,
  } = search;

  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const { data: countriesData, loading: countriesLoading } = useQuery(GET_COUNTRIES);

  const countriesOptions = useMemo(() => {
    if (countriesData) {
      return (countriesData as { countries: Country[] }).countries;
    }
    return [];
  }, [countriesData]);

  useQuery(query, {
    variables: {
      searchTerm: '',
      filter: {
        countryCode: selectedCountry?.countryCode,
        stateCode: selectedState?.stateCode || '',
        city: selectedCity || '',
      },
    },
    errorPolicy: 'ignore',
    skip: !selectedState && !selectedCity,
    onCompleted: (data) => {
      if (data && onSelect) {
        onSelect(data[resultKey]);
      }
    },
  });

  return (
    <SearchContainerMarket>
      <SearchContainer>
        <AegAutocomplete
          testId='search-countries-autocomplete'
          options={orderCountries(countriesOptions)}
          areOptionsLoading={countriesLoading}
          dataDisplayField={displayFields}
          placeholderText={'Select Country'}
          InputComponent={InputContainer}
          defaultValue={null}
          value={selectedCountry}
          forcePopupIcon={true}
          getOptionLabel={(option: any) => option.name as string}
          disableClearable
          onSelect={(...params) => {
            const [selectedOption] = params;
            if (selectedOption && typeof selectedOption !== 'string') {
              setSelectedCountry(selectedOption);
              setSelectedState(null);
              setSelectedCity(null);
            }
          }}
        />
      </SearchContainer>

      {selectedCountry && (
        <StateCityContainer>

          {selectedCountry.states && selectedCountry.states.length > 0 && (
          <SearchStateCity>
            <AegAutocomplete
              testId='search-states-autocomplete'
              options={([...selectedCountry.states].sort((a, b) => (a?.name || '').localeCompare(b?.name || '')))}
              dataDisplayField={displayFields}
              placeholderText={'Select State / Province'}
              InputComponent={InputContainer}
              defaultValue={null}
              value={selectedState}
              forcePopupIcon={true}
              getOptionLabel={(option: any) => option.name as string}
              disableClearable
              onSelect={(...params) => {
                const [selectedOption] = params;
                if (selectedOption && typeof selectedOption !== 'string') {
                  setSelectedState(selectedOption);
                  setSelectedCity(null);
                }
              }}
            />
          </SearchStateCity>
          )}

          <SearchStateCity>
            <AegAutocomplete
              disabled={!selectedCountry?.cities?.length && !selectedState?.cities?.length}
              testId='search-cities-autocomplete'
              options={[...(selectedState?.cities || selectedCountry?.cities || [])].sort()}
              dataDisplayField={displayFields}
              placeholderText={'Select City'}
              InputComponent={InputContainer}
              defaultValue={null}
              value={selectedCity}
              forcePopupIcon={true}
              getOptionLabel={(option: string) => option}
              disableClearable
              onSelect={(...params) => {
                const [selectedOption] = params;
                if (selectedOption && typeof selectedOption === 'string') {
                  setSelectedCity(selectedOption);
                }
              }}
            />
          </SearchStateCity>
        </StateCityContainer>
      )}
    </SearchContainerMarket>
  );
}
