import { StarsFilledIcon } from '@components';
import * as React from 'react';
import {
  SelectChangeEvent, MenuItem, Select, Tooltip,
} from '@mui/material';
import {
  SelectContainer,
  SelectControlContainer,
  SelectItemContainer,
  SelectItemIconContainer,
  SelectLabelContainer,
} from './SelectWithPrimary.styled';

export interface SelectWithPrimaryOptions {
  label: string;
  value: string;
  primary?: boolean;
}

interface SelectWithPrimaryProps {
  value?: string;
  label?: string;
  items: SelectWithPrimaryOptions[];
  onChange: (change: string) => void;
}

export const SelectWithPrimary: React.FC<SelectWithPrimaryProps> = ({
  value,
  label,
  items,
  onChange,
}) => {
  const primaryItemStar = (
    <SelectItemIconContainer data-testid="select-with-primary-icon-container">
      <StarsFilledIcon />
    </SelectItemIconContainer>
  );

  const selectLabel = (
    <SelectLabelContainer data-testid="select-with-primary-label-container">
      {label}
    </SelectLabelContainer>
  );

  const handleChange = (change: SelectChangeEvent<string>) => {
    if (change.target.value !== undefined) {
      onChange(change.target.value);
    }
  };

  return (
    <SelectContainer data-testid="select-with-primary-container">
      {label && selectLabel}
      <SelectControlContainer>
        <Select
          defaultValue={items.find((item) => item.primary)?.value || ''}
          variant="standard"
          onChange={handleChange}
          value={value}
          fullWidth
          data-testid="select-with-primary-select-container"
          inputProps={{
            'data-testid': 'select-with-primary-input',
          }}
        >
          {items.map((item, index) => (
            <MenuItem
              key={`select-with-primary-option-${index}`}
              value={item.value}
              data-testid={`item-select-with-primary-option-${index}`}
            >
              <Tooltip title={item.label} arrow>
                <SelectItemContainer
                  className={item.primary ? 'primary' : 'alternate'}
                  data-testid="select-item-container"
                >
                  {item.primary && primaryItemStar}
                  {item.label}
                </SelectItemContainer>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </SelectControlContainer>
    </SelectContainer>
  );
};
