import React from 'react';
import { EventOfferScenario, ProjectionType, ScenarioProjection } from '@types';
import { formatNumberToCurrency, formatNumberToPercentage } from '@utils/numberHelpers';

import { DivisionCell } from '../DivisionCell/DivisionCell';
import { ValueTypeCell } from '../ValueTypeCell/ValueTypeCell';
import { TotalsCellContainer } from './ScenarioBuilderCell.styled';

interface ScenarioBuilderCellProps extends ScenarioProjection, EventOfferScenario {
  name: string,
  disabled?: boolean
}

export function ScenarioBuilderCell({
  name,
  ticketSales,
  sellableCapacity,
  netGrossPublic,
  totalArtistEarnings,
  clubTourPL,
  type: projectionType,
  amount: projectionAmount,
  disabled,
}: Partial<ScenarioBuilderCellProps>) {
  switch (name) {
    case 'Ticket Sales / Sellable Capacity':
      return (
        <TotalsCellContainer>
          <DivisionCell
            dividend={ticketSales?.toLocaleString() ?? ''}
            divisor={sellableCapacity?.toLocaleString() ?? ''}
          />
        </TotalsCellContainer>
      );
    case 'Net Gross (Public)':
      return (
        <TotalsCellContainer>
          {formatNumberToCurrency(netGrossPublic)}
        </TotalsCellContainer>
      );
    case 'Total Artist Earnings':
      return (
        <TotalsCellContainer>
          {formatNumberToCurrency(totalArtistEarnings)}
        </TotalsCellContainer>
      );
    case 'Club Tour P&L':
      return (
        <TotalsCellContainer>
          {formatNumberToCurrency(clubTourPL)}
        </TotalsCellContainer>
      );
    default:
      return (
        <ValueTypeCell
          defaultValue={projectionAmount ? formatNumberToPercentage(projectionAmount, 0) : ''}
          type={projectionType?.toString() ?? ''}
          options={[
            {
              label: ProjectionType.Average,
              value: ProjectionType.Average,
            },
            {
              label: ProjectionType.Sequential,
              value: ProjectionType.Sequential,
            },
            {
              label: ProjectionType.Manual,
              value: ProjectionType.Manual,
            },
          ]}
          disabled={disabled}
        />
      );
  }
}
