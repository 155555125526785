import { Venue } from '@gql/types/graphql';

export interface ScenarioBuilder {
  name: string;
  eventOffers: ScenarioBuilderEventOffer[];
  summary: ScenarioBuilderEventOffersSummary;
}

export interface ScenarioBuilderEventOffer extends EventOfferBase, CalculatedColumns {}
export interface ScenarioBuilderEventOffersSummary extends CalculatedColumns {
  scenarios: EventOfferScenario[];
}
export interface ScenarioBuilderProjection extends ScenarioProjectionCalculatedColumns {
  scenarios: ScenarioProjection[];
}

export enum ProjectionType {
  Average = 'Average',
  Sequential = 'Sequential',
  Manual = 'Manual',
}

/* ----- Data from GQL ----- */
interface EventOfferBase {
  id: string;
  bundleId: string;
  partitionKey: string;
  eventId: string;
  offerId: string;
  settlementId: string;
  updatedDate: string;
  venue: Venue;
  scenarios: EventOfferScenario[]
}

export interface EventOfferScenario {
  type: ProjectionType | null;
  amount: number | null;
}
/* ------------------------- */

/* ---- Calculated data ---- */
export interface ScenarioProjection {
  ticketSales: number;
  sellableCapacity: number;
  netGrossPublic: number;
  totalArtistEarnings: number;
  clubTourPL: number;
}

interface CalculatedColumns {
  averageBreakeven: EventOfferScenario;
  sequentialBreakeven: EventOfferScenario;
}

interface ScenarioProjectionCalculatedColumns {
  averageBreakeven: ScenarioProjection;
  sequentialBreakeven: ScenarioProjection;
}
/* ------------------------- */
